import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, InputGroup, Container, Col, Row, Table } from 'reactstrap';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import {
	Translate,
	translate,
	ICrudSearchAction,
	ICrudGetAllAction,
	getSortState,
	JhiPagination,
	JhiItemCount
} from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as componentUtility from "app/search/componentUtility";
import { saveAs } from 'file-saver';

import { IRootState } from 'app/shared/reducers';
import { getSearchEntities, getEntities, resetBasket, getMyQuestions, getPdf } from './basket.reducer';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { getEntities as getPreferredContentLanguage } from 'app/entities/user-preferences/user-preferences.reducer';

import BasketButton from 'app/search/basket-button';
import AuthContext from 'app/modules/context/AuthContext';



/* eslint no-console: 0 */
export interface IBasketProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> { }
export const Basket = (props: IBasketProps) => {

	const [search, setSearch] = useState('');
	const [displayLang, setDisplayLang] = useState("en");
	const authContext: any = useContext(AuthContext);

	const [paginationState, setPaginationState] = useState(getSortState(props.location, ITEMS_PER_PAGE));
	const { basketList, match, loading, totalItems, metaInfo } = props;
	const getAllEntities = () => {
		if (search) {
			props.getSearchEntities(
				search,
				paginationState.activePage - 1,
				paginationState.itemsPerPage,
				`${paginationState.sort},${paginationState.order}`
			);
		} else {
			props.getEntities(paginationState.activePage - 1, paginationState.itemsPerPage, `${paginationState.sort},${paginationState.order}`);
		}

	};

	const startSearching = () => {
		if (search) {
			setPaginationState({
				...paginationState,
				activePage: 1
			});
			props.getSearchEntities(
				search,
				paginationState.activePage - 1,
				paginationState.itemsPerPage,
				`${paginationState.sort},${paginationState.order}`
			);
		}
	};

	const clear = () => {
		setSearch('');
		setPaginationState({
			...paginationState,
			activePage: 1
		});
		props.getEntities();
	};

	const handleSearch = event => setSearch(event.target.value);

	useEffect(() => {
		const z = props.getMyQuestions();
		function getToken() {
			if (authContext.isAuthenticated === "true") {
				const token: any = getPreferredContentLanguage();
				const r = Promise.resolve(token);
				r.then(v => {
					v.payload.then(x => {
						setDisplayLang(x.data[0].preferredContentLanguage);
					})
				})
			} else {
				setDisplayLang("en");
			}
		}
		getToken();
	}, [displayLang]);

	const sortEntities = () => {
		getAllEntities();
		props.history.push(
			`${props.location.pathname}?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`
		);
	};

	useEffect(() => {
		sortEntities();

	}, [paginationState.activePage, paginationState.order, paginationState.sort, search]);

	const sort = p => () => {
		setPaginationState({
			...paginationState,
			order: paginationState.order === 'asc' ? 'desc' : 'asc',
			sort: p
		});
	};


	const handlePagination = currentPage => {
		setPaginationState({
			...paginationState,
			activePage: currentPage
		});

	}


	const showPDF = (qRef) => {
		console.info(qRef)
		const pdf: any = getPdf(qRef);
		pdf.payload.then(response => {
			console.info(response)
			const filename = response.headers['content-disposition'].split('filename=')[1];
			const data = response.data;
			const blob = new Blob([data], {
				type: 'application/pdf',
			});
			saveAs(blob, filename.substring(0, filename.length - 1), true);
		})

	}



	return (
		<div>
			<Container>
				<Row>
					<Col sm="3">
					</Col>

					<Col sm="6">
						<h2 id="basket-heading">
							<Translate contentKey="ddiSearchApp.basket.home.title"></Translate>
						</h2>
						
						<h4 id="basket-heading">
							<Translate contentKey="ddiSearchApp.basket.home.subTitle"></Translate>
						</h4>
					</Col>
					<Col sm="3" className="text-right">
						{basketList && basketList.length > 0 ? <a
							onClick={() => showPDF(null)}
						>
							<FontAwesomeIcon icon="file" color="#2b5a9b" />
										&nbsp;all as PDF&nbsp;&nbsp;
									</a> : ""}

						{basketList && basketList.length > 0 ?
							<Button
								tag={Link}
								to={`/basket/deleteall?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
								color="danger"
								size="sm"
							><FontAwesomeIcon icon="trash" />{' '}
											Remove all
									</Button> : ""}
					</Col>
				</Row>
				<div className="table-responsive">
					{basketList && basketList.length > 0 ? (
						<Table responsive>
							<thead>
								<tr>
									<th className="hand" onClick={sort('qRef')}>
										<Translate contentKey="ddiSearchApp.basket.study"></Translate> <FontAwesomeIcon icon="sort" />
									</th>
									<th className="hand" onClick={sort('qRef')}>
										<Translate contentKey="ddiSearchApp.basket.qRef">Q Ref</Translate> <FontAwesomeIcon icon="sort" />
									</th>
									<th className="hand" >
										Text
								</th>
									<th>
										<FontAwesomeIcon icon="file" color="#2b5a9b" />
									</th>
									<th />
								</tr>
							</thead>
							<tbody>
								{basketList.map((basket, i) => (

									<tr key={`entity-${i}`}>
										<td title={props?.metaInfo[basket.qRef] !== undefined && props.metaInfo[basket.qRef] !== null && props.metaInfo[basket.qRef]["studyTitles"] !== undefined ? componentUtility.langHandler(props.metaInfo[basket.qRef]["studyTitles"]["translations"], displayLang, "basket",undefined) : ""}>
											{props.metaInfo[basket.qRef] !== undefined ? props?.metaInfo[basket?.qRef]["studyId"] : ""}</td>
										<td>{basket.qRef}</td>
										<td>
											{props.metaInfo[basket.qRef] !== undefined && props?.metaInfo[basket.qRef]["questionItemTexts"] !== undefined ? componentUtility.langHandler(props.metaInfo[basket.qRef]["questionItemTexts"]["translations"], displayLang, "basket",undefined) : ""}
											{props.metaInfo[basket.qRef] !== undefined && props?.metaInfo[basket.qRef]["questionGridTexts"] !== undefined ? componentUtility.langHandler(props.metaInfo[basket.qRef]["questionGridTexts"]["translations"], displayLang, "basket",undefined) : ""}
										</td>
										<td onClick={() => showPDF(basket.qRef)}>
											<FontAwesomeIcon icon="file" color="#2b5a9b" />PDF
									</td>
										<td className="text-right">
											<div className="btn-group flex-btn-group-container">
												<BasketButton qid={basket.qRef} />

												<Button tag={Link} to={`question/version/${basket.qRef}`} color="info" size="sm">
													<FontAwesomeIcon icon="eye" />{' '}
													<span className="d-none d-md-inline">
														View question
												</span>
												</Button>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</Table>
					) : (
							!loading && (
								<div className="alert alert-warning">
									<Translate contentKey="ddiSearchApp.basket.home.notFound">No Baskets found</Translate>
								</div>
							)
						)}
				</div>
				<div className={basketList && basketList.length > 0 ? '' : 'd-none'}>
					<Row className="justify-content-center">
						<JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
					</Row>
					<Row className="justify-content-center">
						<JhiPagination
							activePage={paginationState.activePage}
							onSelect={handlePagination}
							maxButtons={5}
							itemsPerPage={paginationState.itemsPerPage}
							totalItems={props.totalItems}
						/>
					</Row>
				</div>
			</Container>
		</div>
	);
};

const mapStateToProps = ({ basket }: IRootState) => ({
	basketList: basket.entities,
	loading: basket.loading,
	totalItems: basket.totalItems,
	metaInfo: basket.metaEntities
});

const mapDispatchToProps = {
	getSearchEntities,
	getEntities,
	resetBasket,
	getMyQuestions,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Basket);
