import { faSort } from '@fortawesome/free-solid-svg-icons/faSort';
import { faInfo } from '@fortawesome/free-solid-svg-icons/faInfo';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { faSync } from '@fortawesome/free-solid-svg-icons/faSync';
import { faBan } from '@fortawesome/free-solid-svg-icons/faBan';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { faSave } from '@fortawesome/free-solid-svg-icons/faSave';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons/faPencilAlt';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { faLightbulb} from '@fortawesome/free-solid-svg-icons/faLightbulb';
import { faHdd } from '@fortawesome/free-solid-svg-icons/faHdd';
import { faTachometerAlt } from '@fortawesome/free-solid-svg-icons/faTachometerAlt';
import { faHeart } from '@fortawesome/free-solid-svg-icons/faHeart';
import { faList } from '@fortawesome/free-solid-svg-icons/faList';
import { faTasks } from '@fortawesome/free-solid-svg-icons/faTasks';
import { faBook } from '@fortawesome/free-solid-svg-icons/faBook';
import { faLock } from '@fortawesome/free-solid-svg-icons/faLock';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons/faSignInAlt';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt';
import { faThList } from '@fortawesome/free-solid-svg-icons/faThList';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons/faUserPlus';
import { faWrench } from '@fortawesome/free-solid-svg-icons/faWrench';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons/faAsterisk';
import { faFlag } from '@fortawesome/free-solid-svg-icons/faFlag';
import { faBell } from '@fortawesome/free-solid-svg-icons/faBell';
import { faHome } from '@fortawesome/free-solid-svg-icons/faHome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { faRoad } from '@fortawesome/free-solid-svg-icons/faRoad';
import { faCloud } from '@fortawesome/free-solid-svg-icons/faCloud';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons/faQuestionCircle';
import { faQuestion} from '@fortawesome/free-solid-svg-icons/faQuestion';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons/faCheckSquare';
import { faSquare } from '@fortawesome/free-solid-svg-icons/faSquare';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons/faWindowClose';
import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle';
import { faFile } from '@fortawesome/free-solid-svg-icons/faFile';
import { faLanguage } from '@fortawesome/free-solid-svg-icons/faLanguage';
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons/faExchangeAlt';
import { faShoppingBasket } from '@fortawesome/free-solid-svg-icons/faShoppingBasket';
import { faProjectDiagram} from '@fortawesome/free-solid-svg-icons/faProjectDiagram';
import { faBookOpen} from '@fortawesome/free-solid-svg-icons/faBookOpen';

import { library } from '@fortawesome/fontawesome-svg-core';

export const loadIcons = () => {
	library.add(
		faProjectDiagram,
		faBookOpen,
		faQuestion,
		faQuestionCircle,
		faInfo,
		faSort,
		faEye,
		faSync,
		faBan,
		faLightbulb,
		faTrash,
		faArrowLeft,
		faSave,
		faPlus,
		faPencilAlt,
		faUser,
		faTachometerAlt,
		faHeart,
		faList,
		faTasks,
		faBook,
		faHdd,
		faLock,
		faSignInAlt,
		faSignOutAlt,
		faWrench,
		faThList,
		faUserPlus,
		faAsterisk,
		faFlag,
		faBell,
		faHome,
		faRoad,
		faCloud,
		faTimesCircle,
		faSearch,
		faCheck,
		faSquare,
		faCheckSquare,
		faCircle,
		faWindowClose,
		faShoppingBasket,
		faExchangeAlt,
		faFile,
		faLanguage
	);
};
