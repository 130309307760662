import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, InputGroup, Col, Row, Table } from 'reactstrap';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Translate, translate, ICrudSearchAction, ICrudGetAllAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getSearchEntities, getEntities } from './user-preferences.reducer';
import { IUserPreferences } from 'app/shared/model/user-preferences.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import * as componentUtility from "app/search/componentUtility";

export interface IUserPreferencesProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> { }

export const UserPreferences = (props: IUserPreferencesProps) => {
	const [search, setSearch] = useState('');

	useEffect(() => {
		props.getEntities();
	}, []);

	const startSearching = () => {
		if (search) {
			props.getSearchEntities(search);
		}
	};

	const clear = () => {
		setSearch('');
		props.getEntities();
	};

	const handleSearch = event => setSearch(event.target.value);

	const { userPreferencesList, match, loading } = props;
	return (
		<div>

			<Row><Col sm="3"></Col>
				<Col sm="6">
					<h2 id="user-preferences-heading">
						<Translate contentKey="ddiSearchApp.userPreferences.home.title">User Preferences</Translate>
					</h2>
					<div className="table-responsive">
						{userPreferencesList && userPreferencesList.length > 0 ? (
							<Table responsive>
								<thead>
									<tr>
										<th>
											<Translate contentKey="ddiSearchApp.userPreferences.preferredContentLanguage">Preferred Content Language</Translate>
										</th>
									</tr>
								</thead>
								<tbody>
									{userPreferencesList.map((userPreferences, i) => (
										<tr key={`entity-${i}`}>
											<td>
												{componentUtility.langName(userPreferences.preferredContentLanguage)}

									[{userPreferences.preferredContentLanguage}]
										<Button tag={Link} to={`${match.url}/${userPreferences.id}/edit`} color="link" size="sm">
													<FontAwesomeIcon icon="pencil-alt" />{' '}
												</Button></td>
										</tr>
									))}
								</tbody>
							</Table>
						) : (
								!loading && (
									<div className="alert alert-warning">
										<Translate contentKey="ddiSearchApp.userPreferences.home.notFound">No User Preferences found</Translate>
									</div>
								)
							)}
					</div>
				</Col>
				<Col sm="3"></Col></Row>
		</div>
	);
};

const mapStateToProps = ({ userPreferences }: IRootState) => ({
	userPreferencesList: userPreferences.entities,
	loading: userPreferences.loading,
});

const mapDispatchToProps = {
	getSearchEntities,
	getEntities,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(UserPreferences);
