import './search.scss';
import './customStyle.scss';

import React from 'react';




/* eslint no-console: 0 */
import {
	SearchkitManager, MatchQuery, SearchkitProvider, Hits
} from "searchkit";

import QuestionVerticalItem from "./question-vertical-item";
import appConfig from 'app/config/constants';
const host = appConfig.ELASTICSEARCH_URL;

function QuestionVertical(props) {

	console.info(props);



	const searchkitManager = new SearchkitManager(host);

	const qob = props.qid;
	searchkitManager.addDefaultQuery((query) => {
		return query.addQuery(MatchQuery("_id", qob))
	})
	return (
		<SearchkitProvider key={props.nr} searchkit={searchkitManager}>

			<Hits hitsPerPage={1} itemComponent={<QuestionVerticalItem cmpid={props.cmpid} preferredDisplayLanguage={props.preferredDisplayLanguage} />} />
		</SearchkitProvider>

	);
}


export default QuestionVertical;