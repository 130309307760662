import './header.scss';

import React, { useState } from 'react';
import { Translate, Storage } from 'react-jhipster';
import { Navbar, Nav, NavbarToggler, Collapse, Container, Row, Col } from 'reactstrap';
import HelpMenu from 'app/shared/layout/menus/help-menu';
import BasketMenuButton from 'app/shared/layout/menus/basket-menu';
import ComparisonMenuButton from 'app/shared/layout/menus/comparison-menu';
import LoadingBar from 'react-redux-loading-bar';

import { Intro, Home, About, Help, Brand, Search , StudySearch} from './header-components';

import { AdminMenu, AccountMenu, LocaleMenu } from '../menus';
import { CookieBanner } from '@palmabit/react-cookie-law';

export interface IHeaderProps {
	isAuthenticated: boolean;
	isAdmin: boolean;
	ribbonEnv: string;
	isInProduction: boolean;
	isSwaggerEnabled: boolean;
	currentLocale: string;
	onLocaleChange: Function;
}

const Header = (props: IHeaderProps) => {
	const [menuOpen, setMenuOpen] = useState(false);

	const handleLocaleChange = event => {
		const langKey = event.target.value;
		Storage.session.set('locale', langKey);
		props.onLocaleChange(langKey);
	};

	const renderDevRibbon = () =>
		props.isInProduction === false ? (
			<div className="ribbon dev">
				<a href="https://www.gesis.org/kontakt">
					<Translate contentKey={`global.ribbon.${props.ribbonEnv}`} />
				</a>
			</div>
		) : null;


	const toggleMenu = () => setMenuOpen(!menuOpen);

	/* jhipster-needle-add-element-to-menu - JHipster will add new menu items here */

	return (
		<div id="app-header">
			<div className="nav-wrapper">
				<Container>
					{renderDevRibbon()}
					<LoadingBar className="loading-bar" />

					<Navbar dark expand="sm" className="">
						<a id="gesishome" href="/"><span>
							<img src="content/images/gesis.svg" alt="Logo" /> </span></a>

						<NavbarToggler aria-label="Menu" onClick={toggleMenu} />
						<Collapse isOpen={menuOpen} navbar>
							<Nav id="header-tabs" className="ml-auto" navbar>
								<Search />
								<StudySearch />
								{props.isAuthenticated && <BasketMenuButton />}
								{props.isAuthenticated && <ComparisonMenuButton />}
								{props.isAuthenticated && props.isAdmin && (
									<AdminMenu showSwagger={props.isSwaggerEnabled} showDatabase={!props.isInProduction} />
								)}
								<LocaleMenu currentLocale={props.currentLocale} onClick={handleLocaleChange} />
								<AccountMenu isAuthenticated={props.isAuthenticated} />
							</Nav>
						</Collapse>
					</Navbar>
				</Container>
			</div>

		</div>
	);
};

export default Header;
