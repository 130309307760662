import React from 'react';
import { Container, Row, Col } from 'reactstrap';
/* eslint no-console: 0  no-dupe-keys: 0 */
export const Editor = () => {
	return (
		<Container>
			<Row>
				<Col md="12" >
					<h2>GESIS DDI Questionnaire Editor		</h2>
						DDI Questionnaire Editor is the tool to document your study series or single studies, including variables and questions.
				</Col>
			</Row>
		</Container>
	);
};
export default Editor;

