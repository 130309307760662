import "./apps.scss";
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';

/* eslint no-console: 0  no-dupe-keys: 0 */


export const Apps = () => {

	return (
		<Container>
			<Row>
				<Col md="12" >
					<h1>GESIS DDI App Ecosystem			</h1>
					Find questions, re-use surveys, convert your research documentation
				</Col>
			</Row>





			<Row>
				<Col md="4" >
				</Col>
				<Col md="4">
					<a href="apps#search">
						<Button color="success" size="lg" block >search
				</Button></a>
				</Col><Col md="4" >
				</Col>
			</Row>
			<Row>
				<Col md="2">
				</Col>
				<Col md="4" >
					<a href="apps#editor">
						<Button color="warning" size="lg" block >edit
				</Button></a>
				</Col>
				<Col md="4" >
					<a href="apps#converter">
						<Button color="danger" size="lg" block to="#converter">convert
				</Button></a>
				</Col>
				<Col md="2" >
				</Col>

			</Row>

			<Row>
				<h3 id="search">Question Search</h3>
			</Row>

			<Row>
				<Col md="3" >
					<div className="dotsearch">1</div>
				</Col>
				<Col md="9" >
				</Col>
			</Row>

			<Row>
				<Col md="3" >
					<div className="dotsearch">2</div>
				</Col>
				<Col md="9" >
				</Col>
			</Row>

			<Row><h3 id="editor">Questionnaire Editor</h3>
			</Row>
			<Row>
				<Col md="3">
					<div className="dotedit" >1</div>
				</Col>
				<Col md="9" >
				</Col>
			</Row>

			<Row>
				<Col md="3" >
					<div className="dotedit">2</div>
				</Col>
				<Col md="9" >
				</Col>

			</Row>

			<Row>
				<h3 id="converter">DDI Converter</h3>
			</Row>
			<Row>
				<Col md="3"  >
					<div className="dotconvert">1</div>
				</Col>
				<Col md="9" >
				</Col>
			</Row>

			<Row>
				<Col md="3" >
					<div className="dotconvert" >2</div>
				</Col>
				<Col md="9" >
				</Col>
			</Row>

		</Container >
	);
};
export default Apps;