import React, { useContext, useEffect, useState } from 'react';
import StudyHitItem from "./study-search-item";
/* eslint no-console: 0 */
import AuthContext from 'app/modules/context/AuthContext';

import SearchContext from 'app/modules/context/SearchContext';
import { getEntities as getPreferredContentLanguage } from 'app/entities/user-preferences/user-preferences.reducer';
import { connect } from 'react-redux';


function ResultListComponent(props) {
	console.info("ResultListComponent")
	const [displayLang, setDisplayLang] = useState(undefined);

	const authContext = useContext(AuthContext);
	const dlang = () => {
		if (authContext.isAuthenticated === "true") {
			const promise = getPreferredContentLanguage().payload;
			promise.then(v => {
				if (v.status === 200) {
					console.info(v.data[0])
					setDisplayLang(v.data[0].preferredContentLanguage)
					console.info(displayLang)
				}
				console.info(displayLang)
			});
		}
	};

	useEffect(() => {
		dlang();
	}, [authContext]);


	const searchContext = useContext(SearchContext);

	console.info(displayLang);

	useEffect(() => {
		dlang();
	}, [searchContext]);
	return (
		<div>
			{props.hits.map((hit, index) =>
				<StudyHitItem key={index} result={hit} preferredDisplayLanguage={displayLang} />
			)}
		</div>
	);
}
const mapDispatchToProps = {
	getPreferredContentLanguage
};
connect(mapDispatchToProps)
export default ResultListComponent;