import './footer.scss';

import React from 'react';
import { Container, Col, Row } from 'reactstrap';
const Footer = props => (
	<div className="footer page-content">
		<Container>
			<Row>
				<Col md="3">
					<a href="https://www.gesis.org/en/research/applied-computer-science/labs/wts-research-labs">
						<img src="content/images/gesis_Logo_lang.svg" width="160px" alt="Logo" className="footerlogo"/>
					</a>
				</Col>
				<Col md="6" className="text-center">

					<a className="footerlink" href="https://www.gesis.org/en/institute/data-protection">Privacy Policy</a> <a className="footerlink" href="https://www.gesis.org/en/institute/data-protection">Acceptable Use Policy</a> <a className="footerlink" href="https://www.gesis.org/en/research/applied-computer-science/labs/wts-research-labs">GESIS KTS Research Labs</a>


				</Col>
				<Col md="2" className="sociallinks">
					<a className="footerlink" href="https://twitter.com/gesis_org">
						<svg fill="#fff" preserveAspectRatio="xMidYMid meet" height="24" width="24" viewBox="0 0 40 40" ><g><path d="m31.6 13.6q-1.3 0.6-2.7 0.8 1.5-0.9 2-2.6-1.4 0.8-2.9 1.1-1.4-1.5-3.5-1.5-1.9 0-3.3 1.4t-1.3 3.3q0 0.7 0.1 1.1-2.9-0.2-5.4-1.5t-4.3-3.4q-0.7 1.1-0.7 2.3 0 2.6 2.1 3.9-1.1 0-2.3-0.5v0q0 1.7 1.1 3t2.8 1.6q-0.7 0.2-1.1 0.2-0.3 0-0.9-0.1 0.4 1.4 1.6 2.3t2.8 1q-2.6 2-5.9 2-0.6 0-1.1-0.1 3.3 2.1 7.2 2.1 2.5 0 4.7-0.8t3.7-2.1 2.7-3.1 1.7-3.6 0.5-3.7q0-0.4 0-0.7 1.4-1 2.4-2.4z m5.7-4.3v21.4q0 2.7-1.9 4.6t-4.5 1.8h-21.5q-2.6 0-4.5-1.8t-1.9-4.6v-21.4q0-2.7 1.9-4.6t4.5-1.8h21.5q2.6 0 4.5 1.8t1.9 4.6z"></path></g></svg>
					</a>
				</Col>

			</Row>
		</Container>
	</div>
);

export default Footer;
