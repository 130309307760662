import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';

/* eslint no-console: 0  no-dupe-keys: 0 */


export const Manual = () => {

	return (
		<Container>
			<Row>
				<Col md="12" >
					<h2>GESIS  Question Editor Manual		</h2>
					<div className="transition">
						foo bar
					</div>

					<h2>GESIS  Question Search Manual		</h2>
					<div className="transition">
						foo bar
					</div>

					<h2>GESIS DDI Converter Manual		</h2>
					<div className="transition">
						foo bar
					</div>
				</Col>
			</Row>
		</Container >
	);
};
export default Manual;

