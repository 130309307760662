import { connect } from 'react-redux';
import React, { useContext, useEffect } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { NavLink as Link } from 'react-router-dom';
import Badge from 'react-bootstrap/Badge';
import ComparisonContext from 'app/modules/context/ComparisonContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import { isInMyComparison } from 'app/entities/comparison/comparison.reducer';

/* eslint no-console: 0 */

function ComparisonMenuButton(props) {


	const comparisonContext: any = useContext(ComparisonContext);
	useEffect(() => {
		const promise: any = isInMyComparison(0);
		const payload = promise.payload;
		payload.then(v => {
			comparisonContext.update({ itemsincomparison: v.headers.itemsincomparison })
		});
	}, []);

	return (
		<NavItem icon="asterisk" key="comparisonMenuButton" >
			<NavLink tag={Link} to="/comparison/matrix" className="d-flex align-items-center">
				<FontAwesomeIcon icon="list" color="#fff" />&nbsp;Comparison <Badge id="comparisonBadge"> {comparisonContext.itemsincomparison}</Badge>
			</NavLink>
		</NavItem>
	);
}


const mapStateToProps = (storeState) => ({
	headers: storeState.comparison.headers,
});
const mapDispatchToProps = {
	isInMyComparison,
};
export default connect(mapStateToProps, mapDispatchToProps)(ComparisonMenuButton);