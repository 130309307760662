import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { Translate, ICrudGetAction, ICrudDeleteAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IBasket } from 'app/shared/model/basket.model';
import { IRootState } from 'app/shared/reducers';
import { getEntity, isInMyBasket, deleteEntity } from './basket.reducer';
/* eslint no-console: 0 */

export interface IBasketDeleteDialogProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> { }

export const BasketDeleteDialog = (props: IBasketDeleteDialogProps) => {
	useEffect(() => {
		props.getEntity(props.match.params.id);
	}, []);

	const handleClose = () => {
		props.history.push('/basket' + props.location.search);

	};

	useEffect(() => {
		if (props.updateSuccess) {
			handleClose();
			const el = document.getElementById("basketBadge");

			const p: any = Promise.resolve(props.isInMyBasket(0));
			p.then(v => {
				console.info(v.action.payload.headers.itemsinbasket);
				if (v.action.payload.headers.itemsinbasket === "0") {
					el.innerHTML = "";
				} else {
					el.innerHTML = this.state.headers.itemsinbasket;
				}
			});
		}
	}, [props.updateSuccess]);

	const confirmDelete = () => {
		props.deleteEntity(props.basketEntity.id);
	};

	const { basketEntity } = props;
	return (
		<Modal isOpen toggle={handleClose}>
			<ModalHeader toggle={handleClose}>
				<Translate contentKey="entity.delete.title">Confirm delete operation</Translate>
			</ModalHeader>
			<ModalBody id="ddiSearchApp.basket.delete.question">
				<Translate contentKey="ddiSearchApp.basket.delete.question" interpolate={{ id: basketEntity.id }}>
					Are you sure you want to delete this Basket?
        </Translate>
			</ModalBody>
			<ModalFooter>
				<Button color="secondary" onClick={handleClose}>
					<FontAwesomeIcon icon="ban" />
          &nbsp;
          <Translate contentKey="entity.action.cancel">Cancel</Translate>
				</Button>
				<Button id="jhi-confirm-delete-basket" color="danger" onClick={confirmDelete}>
					<FontAwesomeIcon icon="trash" />
          &nbsp;
          <Translate contentKey="entity.action.delete">Delete</Translate>
				</Button>
			</ModalFooter>
		</Modal>
	);
};

const mapStateToProps = ({ basket }: IRootState) => ({
	basketEntity: basket.entity,
	updateSuccess: basket.updateSuccess
});

const mapDispatchToProps = { getEntity, deleteEntity, isInMyBasket };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(BasketDeleteDialog);
