import './search.scss';
import './customStyle.scss';

import { Link, RouteComponentProps } from 'react-router-dom';
import React, { Component, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import * as componentUtility from "./componentUtility";

/* eslint no-console: 0 */

export class EQBStudyDocumentationDownloadItem extends Component {

	constructor(props) {
		super(props)
		this.state = {
			myLang: "en"
		}
		this.langToggle = React.createRef();
	}

	setMyLang(event) {
		console.info(event);
		this.setState({ myLang: event })
	}




	otherMaterial(data) {
		const ds = [];
		console.info(data);
		if (data !== undefined) {
			ds.push(<Row key="-1"	><Col className="caption">Title</Col><Col className="caption">Type:</Col></Row>);

			for (let i = 0; i < data.length; i++) {
				let linktext = (data[i].title !== undefined ? data[i].title : data[i].externalURLReference);
				if (linktext === undefined || linktext === "") {
					linktext = "No link or title provided";
				} else {
					linktext= linktext.replace("https://dbk.gesis.org/dbksearch/download.asp?id=", "https://access.gesis.org/dbk/")
					ds.push(<Row key={i}>
						<Col><a href={data[i].externalURLReference.replace("https://dbk.gesis.org/dbksearch/download.asp?id=", "https://access.gesis.org/dbk/")}>{linktext}</a></Col>
						<Col>{data[i].publicationType}</Col>
					</Row>);
				}
			}
		} else {
			ds.push(<Row key="-1"	><Col className="caption">No additional material for this study available	</Col></Row>);
		}
		return ds;
	}

	render() {

		const { result } = this.props


		let studyTitle = undefined;
		studyTitle = componentUtility.langHandler(result._source.studyTitles.translations, this.state.myLang);


		if (studyTitle === undefined || studyTitle.length === 0) {
			studyTitle = componentUtility.langHandler(result._source.title, "en");
		}



		if (studyTitle === undefined || studyTitle === "") { studyTitle = ["no title"] }
		let otherMaterial = this.otherMaterial(result._source.studyDocumentation);
		if (otherMaterial === undefined) {
			otherMaterial = "Not available";
		}

		return (
			<div >
				<Container>
					<Row>
						<Col md="3">
							<a href="/">

							</a>

						</Col>
						<Col md="9">
							<h2>Study documentation details for {studyTitle}</h2>
						</Col></Row>
				</Container><Container className="alternating">
					{otherMaterial}
				</Container>
			</div>)
	}
}
export default EQBStudyDocumentationDownloadItem;