import './search.scss';
import './customStyle.scss';
import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Button, UncontrolledCollapse } from 'reactstrap';
import * as componentUtility from "../search/componentUtility";
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { MatchQuery, SearchkitManager, SearchkitProvider, SearchBox, Hits, LayoutResults, Layout, LayoutBody } from "searchkit";
import { Helmet } from 'react-helmet';

import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import ReadMoreReact from "read-more-react";
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StudyDetailItem from './study-detail-item'

/* eslint no-console: 0 complexity: 0*/


import appConfig from 'app/config/constants';
const host = appConfig.ELASTICSEARCH_URL_STUDY;
let searchkitManager = new SearchkitManager(host);
export class StudyDetail extends Component {


	constructor(props) {
		super(props)
		this.state = {
			myLang: "en"
		}
		console.info(props);
		this.langToggle = React.createRef();
	}

	setMyLang(event) {
		console.info(event);
		console.info(event);
		console.info(event);
		console.info(event);
		console.info(event);
		this.setState({ myLang: event })
	}


	langSelector(data) {
		const ds = [];
		data = data.filter((item, index) => data.indexOf(item) === index);
		for (let i = 0; i < data.length; i++) {
			ds.push(<Dropdown.Item onSelect={e => this.setMyLang(data[i])} size="sm" key={data[i]}>{data[i]}</Dropdown.Item>);
		}
		return <DropdownButton className="langsel" id="dropdown-display-lang" title={"Display language: " + this.state.myLang}>
			{ds}
		</DropdownButton>;
	}



	render() {

		const { bemBlocks, result } = this.props
		console.info("");
		const questionEsId = this.props.location.pathname.substr(this.props.location.pathname.lastIndexOf('/') + 1);
		console.log("questionEsId: " + questionEsId);
		searchkitManager = new SearchkitManager(host);
		searchkitManager.addDefaultQuery((query) => {
			return query.addQuery(MatchQuery("_id", questionEsId))
		});



		return (
			<SearchkitProvider searchkit={searchkitManager}>
				<Layout>
					<Helmet>
						<title>Question detail</title>
					</Helmet>
					<LayoutBody>
						<LayoutResults>
							<SearchBox mod="searchBoxQuestionDetail" searchOnChange={true} />
							<Hits hitsPerPage={1} className="is-12" itemComponent={<StudyDetailItem key={questionEsId} qid={questionEsId} />} />
						</LayoutResults>
					</LayoutBody>
				</Layout>
			</SearchkitProvider>
		);
	}


}
export default StudyDetail;