import axios from 'axios';
import { ICrudSearchAction, ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IBasket, defaultValue } from 'app/shared/model/basket.model';
/* eslint no-console: 0  no-dupe-keys: 0 */
/* eslint complexity: [0,0] */
export const ACTION_TYPES = {
	SEARCH_BASKETS: 'basket/SEARCH_BASKETS',
	FETCH_BASKET_LIST: 'basket/FETCH_BASKET_LIST',
	FETCH_BASKET: 'basket/FETCH_BASKET',
	PDF_BASKET: 'basket/PDF_BASKET',
	STUDY_BASKET: 'basket/STUDY_BASKET',
	CREATE_BASKET: 'basket/CREATE_BASKET',
	UPDATE_BASKET: 'basket/UPDATE_BASKET',
	TOGGLE_BASKET: 'basket/TOGGLE_BASKET',
	ALLIN_BASKET: 'basket/ADD_BASKET',
	ADD_BASKET: 'basket/ADD_BASKET',
	REMOVE_BASKET: 'basket/REMOVE_BASKET',
	DELETE_BASKET: 'basket/DELETE_BASKET',
	RESET_BASKET: 'basket/RESET_BASKET',
	RESET: 'basket/RESET'
};

const initialState = {
	loading: false,
	errorMessage: null,
	entities: [] as ReadonlyArray<IBasket>,
	metaEntities: [] as ReadonlyArray<any>,
	entity: defaultValue,
	updating: false,
	totalItems: 0,
	updateSuccess: false
};

export type BasketState = Readonly<typeof initialState>;

// Reducer

export default (state: BasketState = initialState, action): BasketState => {
	switch (action.type) {
		case REQUEST(ACTION_TYPES.SEARCH_BASKETS):
		case REQUEST(ACTION_TYPES.FETCH_BASKET_LIST):
		case REQUEST(ACTION_TYPES.FETCH_BASKET):
		case REQUEST(ACTION_TYPES.PDF_BASKET):
		case REQUEST(ACTION_TYPES.STUDY_BASKET):
			return {
				...state,
				errorMessage: null,
				updateSuccess: false,
				loading: true
			};
		case REQUEST(ACTION_TYPES.CREATE_BASKET):
		case REQUEST(ACTION_TYPES.UPDATE_BASKET):
		case REQUEST(ACTION_TYPES.TOGGLE_BASKET):
		case REQUEST(ACTION_TYPES.ADD_BASKET):
		case REQUEST(ACTION_TYPES.ALLIN_BASKET):
		case REQUEST(ACTION_TYPES.REMOVE_BASKET):
		case REQUEST(ACTION_TYPES.DELETE_BASKET):
		case REQUEST(ACTION_TYPES.RESET_BASKET):
			return {
				...state,
				errorMessage: null,
				updateSuccess: false,
				updating: true
			};
		case FAILURE(ACTION_TYPES.SEARCH_BASKETS):
		case FAILURE(ACTION_TYPES.FETCH_BASKET_LIST):
		case FAILURE(ACTION_TYPES.FETCH_BASKET):
		case FAILURE(ACTION_TYPES.PDF_BASKET):
		case FAILURE(ACTION_TYPES.STUDY_BASKET):
		case FAILURE(ACTION_TYPES.CREATE_BASKET):
		case FAILURE(ACTION_TYPES.UPDATE_BASKET):
		case FAILURE(ACTION_TYPES.TOGGLE_BASKET):
		case FAILURE(ACTION_TYPES.ADD_BASKET):
		case FAILURE(ACTION_TYPES.ALLIN_BASKET):
		case FAILURE(ACTION_TYPES.REMOVE_BASKET):
		case FAILURE(ACTION_TYPES.DELETE_BASKET):
		case FAILURE(ACTION_TYPES.RESET_BASKET):
			return {
				...state,
				loading: false,
				updating: false,
				updateSuccess: false,
				errorMessage: action.payload
			};
		case SUCCESS(ACTION_TYPES.SEARCH_BASKETS):
		case SUCCESS(ACTION_TYPES.FETCH_BASKET_LIST):
			return {
				...state,
				loading: false,
				entities: action.payload.data,
				totalItems: parseInt(action.payload.headers['x-total-count'], 10)
			};
		case SUCCESS(ACTION_TYPES.STUDY_BASKET):
			return {
				...state,
				loading: false,
				metaEntities: action.payload.data
			};
		case SUCCESS(ACTION_TYPES.FETCH_BASKET):
		case SUCCESS(ACTION_TYPES.PDF_BASKET):
			return {
				...state,
				loading: false,
				entity: action.payload.data
			};
		case SUCCESS(ACTION_TYPES.CREATE_BASKET):
		case SUCCESS(ACTION_TYPES.UPDATE_BASKET):
		case SUCCESS(ACTION_TYPES.TOGGLE_BASKET):
		case SUCCESS(ACTION_TYPES.REMOVE_BASKET):
		case SUCCESS(ACTION_TYPES.ADD_BASKET):
		case SUCCESS(ACTION_TYPES.ALLIN_BASKET):
			return {
				...state,
				updating: false,
				updateSuccess: true,
				entity: action.payload.data
			};
		case SUCCESS(ACTION_TYPES.DELETE_BASKET):
		case SUCCESS(ACTION_TYPES.RESET_BASKET):
			return {
				...state,
				updating: false,
				updateSuccess: true,
				entity: {}
			};
		case ACTION_TYPES.RESET:
			return {
				...initialState
			};
		default:
			return state;
	}
};

const myApiUrl = 'api/baskets/my';
const myApiAddUrl = 'api/baskets/my-add';
const myApiRemoveUrl = 'api/baskets/my-remove';
const myApiPdfUrl = 'api/baskets/pdf/my';
const apiUrl = 'api/baskets';
const apiSearchUrl = 'api/_search/baskets';

// Actions

export const getSearchEntities: ICrudSearchAction<IBasket> = (query, page, size, sort) => ({
	type: ACTION_TYPES.SEARCH_BASKETS,
	payload: axios.get<IBasket>(`${apiSearchUrl}?query=${query}${sort ? `&page=${page}&size=${size}&sort=${sort}` : ''}`)
});

export const getEntities: ICrudGetAllAction<IBasket> = (page, size, sort) => {
	const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
	return {
		type: ACTION_TYPES.FETCH_BASKET_LIST,
		payload: axios.get<IBasket>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
	};
};

export const getMyQuestions: any = () => {
	const requestUrl = `${apiUrl}/studyInfo`;
	return {
		type: ACTION_TYPES.STUDY_BASKET,
		payload: axios.get<IBasket>(`${requestUrl}`)
	};
};


export const isInMyBasket: ICrudGetAction<IBasket> = id => {
	const requestUrl = `${myApiUrl}/${id}`;

	return {
		type: ACTION_TYPES.FETCH_BASKET,
		payload: axios.get<IBasket>(requestUrl)
	};
};

export const toggleInMyBasket: ICrudPutAction<IBasket> = id => {
	const requestUrl = `${myApiUrl}/toggle/${id}`;
	return {
		type: ACTION_TYPES.TOGGLE_BASKET,
		payload: axios.get<IBasket>(requestUrl)
	};
};


export const getPdf: ICrudGetAction<IBasket> = id => {
	const requestUrl = `${myApiPdfUrl}/${id}`;
	return {
		type: ACTION_TYPES.PDF_BASKET,
		payload: axios.get<any>(requestUrl, {
			responseType: 'arraybuffer',
			headers: {
				'Accept': 'application/pdf'
			}
		})
	};
};

export const addInMyBasket: ICrudPutAction<IBasket> = id => {
	const requestUrl = `${myApiAddUrl}/${id}`;
	return {
		type: ACTION_TYPES.ADD_BASKET,
		payload: axios.get<IBasket>(requestUrl)
	};
};


export const removeInMyBasket: ICrudPutAction<IBasket> = id => {
	const requestUrl = `${myApiRemoveUrl}/${id}`;
	return {
		type: ACTION_TYPES.REMOVE_BASKET,
		payload: axios.get<IBasket>(requestUrl)
	};
};

export const getEntity: ICrudGetAction<IBasket> = id => {
	const requestUrl = `${apiUrl}/${id}`;
	return {
		type: ACTION_TYPES.FETCH_BASKET,
		payload: axios.get<IBasket>(requestUrl)
	};
};

export const createEntity: ICrudPutAction<IBasket> = entity => async dispatch => {
	const result = await dispatch({
		type: ACTION_TYPES.CREATE_BASKET,
		payload: axios.post(apiUrl, cleanEntity(entity))
	});
	dispatch(getEntities());
	return result;
};

export const updateEntity: ICrudPutAction<IBasket> = entity => async dispatch => {
	const result = await dispatch({
		type: ACTION_TYPES.UPDATE_BASKET,
		payload: axios.put(apiUrl, cleanEntity(entity))
	});
	return result;
};

export const deleteEntity: ICrudDeleteAction<IBasket> = id => async dispatch => {
	const requestUrl = `${apiUrl}/${id}`;
	const result = await dispatch({
		type: ACTION_TYPES.DELETE_BASKET,
		payload: axios.delete(requestUrl)
	});
	return result;
};

export const resetBasket: ICrudGetAction<IBasket> = () => {
	const requestUrl = `${myApiUrl}/reset`;
	return {
		type: ACTION_TYPES.RESET_BASKET,
		payload: axios.get<IBasket>(`${requestUrl}`)
	};
};


export const reset = () => ({
	type: ACTION_TYPES.RESET
});
