import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { Translate, ICrudGetAction, ICrudDeleteAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IBasket } from 'app/shared/model/basket.model';
import { IRootState } from 'app/shared/reducers';
import { getEntities, deleteEntity } from './basket.reducer';
/* eslint no-console: 0  no-dupe-keys: 0 */

export interface IBasketDeleteDialogProps extends StateProps, DispatchProps, RouteComponentProps<{}> { }

export const BasketDeleteAllDialog = (props: IBasketDeleteDialogProps) => {
	useEffect(() => {
		const z = props.getEntities();
		const p: any = Promise.resolve(z);
		p.then(v => {
			console.log(v.action.payload.data);
			v.action.payload.data.map((basket, i) =>
			{
				console.info(i + ":" + basket.id);
				props.deleteEntity(basket.id)
			});
			const el =document.getElementById("basketBadge");
			el.innerHTML="0";
		});
		console.info();
	}, []);

	const handleClose = () => {
		props.history.push('/basket/');
	};

	useEffect(() => {
		if (props.updateSuccess) {
			handleClose();
		}
	}, [props.updateSuccess]);

	const confirmDeleteAll = () => {
		props.deleteEntity(props.basketEntity.id);
	};

	const { basketEntity } = props;
	return (
		<Modal isOpen toggle={handleClose}>
			<ModalHeader toggle={handleClose}>
				<Translate contentKey="entity.delete.title">Confirm delete all operation</Translate>
			</ModalHeader>
			<ModalBody id="ddiSearchApp.basket.delete.question">
				Are you sure you want to delete all questions from basket
			</ModalBody>
			<ModalFooter>
				<Button color="secondary" onClick={handleClose}>
					<FontAwesomeIcon icon="ban" />
          &nbsp;
          <Translate contentKey="entity.action.cancel">Cancel</Translate>
				</Button>
				<Button id="jhi-confirm-delete-basket" color="danger" onClick={confirmDeleteAll}>
					<FontAwesomeIcon icon="trash" />
          &nbsp;
          <Translate contentKey="entity.action.delete">Delete</Translate>
				</Button>
			</ModalFooter>
		</Modal>
	);
};

const mapStateToProps = ({ basket }: IRootState) => ({
	basketEntity: basket.entity,
	updateSuccess: basket.updateSuccess
});

const mapDispatchToProps = { getEntities, deleteEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(BasketDeleteAllDialog);
