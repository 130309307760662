import './search.scss';
import './customStyle.scss';
import React, { Fragment, useContext, useEffect, useState, useRef } from 'react';
import * as componentUtility from "./componentUtility";
import { Card, CardBody, CardHeader, CardTitle, CardText } from 'reactstrap';

import AuthContext from 'app/modules/context/AuthContext';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';
import BasketButton from 'app/search/basket-button';
import ComparisonButton from 'app/search/comparison-button';

import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import BootstrapTable from 'react-bootstrap-table-next';

/* eslint no-console: 0 */


import appConfig from 'app/config/constants';
const host = appConfig.ELASTICSEARCH_URL;
function QuestionVerticalItem(props) {

	const { result } = props;
	const authContext = useContext(AuthContext);
	const [displayLang, setDisplayLang] = useState(props.preferredDisplayLanguage);

	const rawJson = host + "/_doc/" + result._id;
	const langToggle = useRef();


	const [studyTitle, setStudyTitle] = useState([]);
	const [answerCategories, setAnswerCategories] = useState([]);

	const [studyDescription, setStudyDescription] = useState([]);
	const [questionItemText, setQuestionItemText] = useState([]);
	const [subQuestionText, setSubQuestionText] = useState([]);
	const [questionGridText, setQuestionGridText] = useState([]);
	const [questionItemInterviewerInstruction, setQuestionItemInterviewerInstruction] = useState([]);
	const [questionGridInterviewerInstruction, setQuestionGridInterviewerInstruction] = useState([]);
	const [questionItemName, setQuestionItemName] = useState([]);
	const [questionGridName, setQuestionGridName] = useState([]);
	const [questionItemStatement, setQuestionItemStatement] = useState([]);
	const [questionGridStatement, setQuestionGridStatement] = useState([]);
	let effctvDsplyLng = props.preferredDisplayLanguage ? props.preferredDisplayLanguage : "en";
	const [relatedVariables, setRelatedVariables] = useState([]);
	const vcolumns = [{
		dataField: 'index',
		text: 'Order',
		sort: true
	}, {
		dataField: 'study',
		text: 'Study',
		sort: true
	}, {
		dataField: 'varr',
		text: 'Var name',
		sort: true
	}, {
		dataField: 'year',
		text: 'Study year',
		sort: true
	}, {
		dataField: 'yearLink',
		text: 'Search ',
		sort: false
	}];

	const variablePopover = (
		<Popover id="popover-varaibles" placement="top">
			<Popover.Title as="h6">Related variables</Popover.Title>
			<Popover.Content>
				<BootstrapTable keyField='index' data={relatedVariables} columns={vcolumns} />
			</Popover.Content>
		</Popover>
	);

	function bestMatchingLang(data, la, dropdown, override) {
		console.info("override:" + override)
		if (override) {
			la = dropdown;
		}
		console.info("props.preferredDisplayLanguage:" + props.preferredDisplayLanguage)
		if (data === undefined) return;
		let preSelLang = override ? dropdown : effctvDsplyLng;
		console.info("preSelLang:" + preSelLang)
		console.info("dropdown:" + dropdown)
		if (dropdown.length > 2 && dropdown.startsWith(preSelLang) && dropdown.endsWith(preSelLang)) {
			return dropdown;
		}
		console.info("availableLangs:" + data)
		if (preSelLang === undefined) {
			preSelLang = data.filter((item, index) => item.startsWith(la) && item.endsWith(la));
		}
		console.info("preSelLang:" + preSelLang)
		if (preSelLang === undefined) {
			preSelLang = data.filter((item, index) => item.startsWith(la));
		}
		console.info("preSelLang:" + preSelLang)
		if (preSelLang === undefined) {
			preSelLang = la;
		}
		console.info("la:" + la)
		console.info("dropdown:" + dropdown)
		console.info("preSelLang:" + preSelLang)
		return preSelLang;
	}
	const setdisplayLang = (event) => {
		effctvDsplyLng = bestMatchingLang(result._source.languagesCountries, effctvDsplyLng, event, true);
	}

	const defineBEMBlocks = () => {
		return {
			"container": "container",
			"option": "item"
		}
	}


	function getQuestionGridText() {
		let questionGridText1 = undefined;
		if (result._source.questionGridTexts !== undefined) {
			if (result.highlight !== undefined) {
				questionGridText1 = result.highlight['questionGridTexts.translations.' + effctvDsplyLng];
				questionGridText1 = componentUtility.txt2Html(questionGridText1);
				if (questionGridText1 === undefined) {
					questionGridText1 = componentUtility.langHandler(result._source.questionGridTexts?.translations, effctvDsplyLng);
				}
			}
			else {
				questionGridText1 = componentUtility.langHandler(result._source.questionGridTexts?.translations, effctvDsplyLng);
			}
		}
		return questionGridText1 === undefined ? [] : questionGridText1;
	}



	function getSubQuestionText() {
		let subQuestionText1 = undefined;
		if (result._source.subQuestionTexts !== undefined) {
			if (result.highlight !== undefined) {
				subQuestionText1 = result.highlight['subQuestionTexts.translations.' + effctvDsplyLng];
				subQuestionText1 = componentUtility.txt2Html(subQuestionText1);
				if (subQuestionText1 === undefined) {
					subQuestionText1 = componentUtility.langHandler(result._source.subQuestionTexts?.translations, effctvDsplyLng);
				}
			}
			else {
				subQuestionText1 = componentUtility.langHandler(result._source.subQuestionTexts?.translations, effctvDsplyLng);
			}
		}
		return subQuestionText1 === undefined ? [] : subQuestionText1 = subQuestionText1.map((gr, index) => <p key={"vl-" + index + subQuestionText1[index]} >{subQuestionText1[index]} | {gr} </p>);
	}





	function getQuestionItemText() {
		let questionItemText1 = undefined;
		if (result._source.questionItemTexts !== undefined) {
			if (result.highlight !== undefined) {
				questionItemText1 = result.highlight['questionItemTexts.translations.' + effctvDsplyLng];
				questionItemText1 = componentUtility.txt2Html(questionItemText1);
				if (questionItemText1 === undefined) {
					questionItemText1 = componentUtility.langHandler(result._source.questionItemTexts?.translations, effctvDsplyLng);
				}
			}
			else {
				questionItemText1 = componentUtility.langHandler(result._source.questionItemTexts?.translations, effctvDsplyLng);
			}
		}
		return questionItemText1 === undefined ? [] : questionItemText1;
	}

	function getQuestionItemName() {
		let val = undefined;
		if (result._source.questionItemNames !== undefined) {
			if (result.highlight !== undefined) {
				val = result.highlight['questionItemNames.translations.' + effctvDsplyLng];
				val = componentUtility.txt2Html(val);
				if (val === undefined) {
					val = componentUtility.langHandler(result._source.questionItemNames?.translations, effctvDsplyLng);
				}
			}
			else {
				val = componentUtility.langHandler(result._source.questionItemNames?.translations, effctvDsplyLng);
			}
		}
		return val === undefined ? [] : val;
	}


	function getQuestionGridStatement() {
		let val = undefined;
		if (result._source.questionGridStatements !== undefined) {
			if (result.highlight !== undefined) {
				val = result.highlight['questionGridStatements.translations.' + effctvDsplyLng];
				val = componentUtility.txt2Html(val);
				if (val === undefined) {
					val = componentUtility.langHandler(result._source.questionGridStatements?.translations, effctvDsplyLng);
				}
			}
			else {
				val = componentUtility.langHandler(result._source.questionGridStatements?.translations, effctvDsplyLng);
			}
		}
		return val === undefined ? [] : val;
	}



	function getQuestionGridName() {
		let val = undefined;
		if (result._source.questionGridName !== undefined) {
			if (result.highlight !== undefined) {
				val = result.highlight['questionGridNames.translations.' + effctvDsplyLng];
				val = componentUtility.txt2Html(val);
				if (val === undefined) {
					val = componentUtility.langHandler(result._source.questionGridNames?.translations, effctvDsplyLng);
				}
			}
			else {
				val = componentUtility.langHandler(result._source.questionGridNames?.translations, effctvDsplyLng);
			}
		}
		return val === undefined ? [] : val;
	}


	function getQuestionItemStatement() {
		let val = undefined;
		if (result._source.questionItemStatements !== undefined) {
			if (result.highlight !== undefined) {
				val = result.highlight['questionItemStatements.translations.' + effctvDsplyLng];
				val = componentUtility.txt2Html(val);
				if (val === undefined) {
					val = componentUtility.langHandler(result._source.questionItemStatements?.translations, effctvDsplyLng);
				}
			}
			else {
				val = componentUtility.langHandler(result._source.questionItemStatements?.translations, effctvDsplyLng);
			}
		}
		return val === undefined ? [] : val;
	}



	function getQuestionItemInterviewerInstruction() {
		let val = undefined;
		if (result._source.questionItemInterviewerInstructions !== undefined) {
			if (result.highlight !== undefined) {
				val = result.highlight['questionItemInterviewerInstructions.translations.' + effctvDsplyLng];
				val = componentUtility.txt2Html(val);
				if (val === undefined) {
					val = componentUtility.langHandler(result._source.questionItemInterviewerInstructions?.translations, effctvDsplyLng);
				}
			}
			else {
				val = componentUtility.langHandler(result._source.questionItemInterviewerInstructions?.translations, effctvDsplyLng);
			}
		}
		return val === undefined ? [] : val;
	}

	function getQuestionGridInterviewerInstruction() {
		let val = undefined;
		if (result._source.questionGridInterviewerInstructions !== undefined) {
			if (result.highlight !== undefined) {
				val = result.highlight['questionGridInterviewerInstructions.translations.' + effctvDsplyLng];
				val = componentUtility.txt2Html(val);
				if (val === undefined) {
					val = componentUtility.langHandler(result._source.questionGridInterviewerInstructions?.translations, effctvDsplyLng);
				}
			}
			else {
				val = componentUtility.langHandler(result._source.questionGridInterviewerInstructions?.translations, effctvDsplyLng);
			}
		}
		return val === undefined ? [] : val;
	}

		function getRelatedVariables() {

		if (result._source.relatedVariables !== undefined) {
			result._source.relatedVariables.map((ac, index) => {
				console.info(ac);
				const yearLink = <a href={'/search?studyIDs[0]=' + ac.study + '&varNames[0]=' + ac.var}>	<FontAwesomeIcon icon="search" /></a>;
				const study = ac.study;
				const varr = ac.var;
				const year = ac.startEndYearStartYear;

				const v = { index, study, varr, year, yearLink };
				relatedVariables.push(v);
			});
		} else {
				setRelatedVariables([]);
			}
		
		console.error(relatedVariables);
		return relatedVariables;
	}
	function getAnswerCategories() {
		let val = undefined;
		if (result._source.answerCategories !== undefined) {
			if (result.highlight !== undefined) {
				val = result.highlight['answerCategories.translations.' + effctvDsplyLng];
				val = componentUtility.txt2Html(val);
				if (val === undefined) {
					val = componentUtility.langHandler(result._source.answerCategories?.translations, effctvDsplyLng);
				}
			}
			else {
				val = componentUtility.langHandler(result._source.answerCategories?.translations, effctvDsplyLng);
			}
		}
		return val === undefined ? [] : val;
	}



	function getStudyDescriptions() {
		let studyDescription1 = undefined;
		studyDescription1 = componentUtility.langHandler(result._source.studyDescriptions?.translations, effctvDsplyLng);
		return studyDescription1 === undefined ? [] : studyDescription1;
	}

	function getStudyTitle() {
		let studyTitle1 = undefined;
		studyTitle1 = componentUtility.langHandler(result._source.studyTitles?.translations, effctvDsplyLng);
		return studyTitle1 === undefined ? [] : studyTitle1;
	}

	function update() {
		setAnswerCategories(getAnswerCategories())
		setStudyTitle(getStudyTitle())
		setStudyDescription(getStudyDescriptions())
		setQuestionItemText(getQuestionItemText())
		setSubQuestionText(getSubQuestionText())
		setQuestionGridText(getQuestionGridText())
		setQuestionItemName(getQuestionItemName())
		setQuestionGridName(getQuestionGridName())
		setQuestionItemStatement(getQuestionItemStatement())
		setQuestionGridStatement(getQuestionGridStatement())
		setQuestionItemInterviewerInstruction(getQuestionItemInterviewerInstruction())
		setQuestionGridInterviewerInstruction(getQuestionGridInterviewerInstruction())
		setRelatedVariables(getRelatedVariables())

	}
	const langSelector = (data) => {
		const ds = [];
		data = data.filter((item, index) => data.indexOf(item) === index);
		data = data.sort();
		if (data.length > 1) {
			ds.push(<option key={result._id + "-null"} value={effctvDsplyLng}>
				{componentUtility.langName(effctvDsplyLng)}</option>);
		}
		for (let i = 0; i < data.length; i++) {
			ds.push(<option value={data[i]} size="sm" key={"1" + data[i]}>{componentUtility.langName(data[i])}</option>);
		}
		return <select ref={langToggle}
			className="btn btn-primary langsel btn-sm"
			defaultValue={effctvDsplyLng}
			selectedOptions={[effctvDsplyLng]}
			title={"Display fields in " + displayLang}
			onChange={e => { setdisplayLang(langToggle?.current.selectedOptions[0].value); update() }}  >
			{ds}
		</select>;

	}
	function getLanguages() {
		return langSelector(componentUtility.languagesOfItemAndGridTexts(result));
	}
	const langSwitch = getLanguages();
	effctvDsplyLng = bestMatchingLang(result._source.languagesCountries, effctvDsplyLng,
		langToggle?.current?.selectedOptions !== undefined && langToggle?.current?.selectedOptions[0] !== undefined ? langToggle?.current?.selectedOptions[0].value : "en");


	useEffect(
		() => {
			console.info("")
			setStudyTitle(getStudyTitle())
			setStudyDescription(getStudyDescriptions())
			setQuestionItemText(getQuestionItemText())
			setSubQuestionText(getSubQuestionText())
			setQuestionGridText(getQuestionGridText())
			setQuestionItemName(getQuestionItemName())
			setQuestionGridName(getQuestionGridName())
			setQuestionItemStatement(getQuestionItemStatement())
			setQuestionGridStatement(getQuestionGridStatement())
			setQuestionItemInterviewerInstruction(getQuestionItemInterviewerInstruction())
			setQuestionGridInterviewerInstruction(getQuestionGridInterviewerInstruction())
			setRelatedVariables(getRelatedVariables())

		}, [result, displayLang]);

	let variableNames = undefined;
	if (result._source.variableNames !== undefined) {
		variableNames = componentUtility.langHandler(result._source.variableNames.translations, displayLang, result._id);
	}
	let variables = "";
	let variableLabels = undefined;
	if (result._source.variableLabels !== undefined) {
		variableLabels = componentUtility.langHandler(result._source.variableLabels.translations, displayLang, "vars" + result._id);
		if (variableLabels !== undefined) {
			variables = variableLabels.map((gr, index) =>
				<p key={"2" + index} >{variableNames[index]} {index < variableLabels.length - 1 ? " | " : " n/a "} {gr}</p>
			);
		}
	}





	return (
		<Fragment>
			<Card >
				<CardHeader>
					<Button title="View question details"
						tag={Link} to={`/question/version/${result._id}`} color="info" size="sm">
						<FontAwesomeIcon icon="eye" /> Detail
					</Button>
					{langSwitch}
					<BasketButton qid={result._id} size="sm" />
					<ComparisonButton qid={result._id} size="sm" />
					{authContext.isAdmin === "true" ? <span><a className="btn btn-primary btn-sm" href={rawJson} target="json" >&nbsp;json</a></span> : ""}
				</CardHeader>
				<CardBody>
					<CardTitle className="caption">Question text:	</CardTitle>
					<CardText className="qtext"> {questionItemText} {questionGridText}</CardText>
					<CardTitle className="caption">Name:	</CardTitle>
					<CardText className="qtext"> {questionItemName}{questionGridName} </CardText>

					<CardTitle className="caption">Pre-/Post-question text:	</CardTitle>
					<CardText className="qtextmed"> {questionItemStatement} {questionGridStatement}</CardText>

					<CardTitle className="caption">Question ID:	</CardTitle>
					<CardText className="qtextshort">{result._source.questionItemID}</CardText>

					<CardTitle className="caption">Version:	</CardTitle>
					<CardText className="qtextshort">{result._source.questionItemVersion}</CardText>

					<CardTitle className="caption">Interviewer instruction	</CardTitle>
					<CardText className="qtextmed">{questionItemInterviewerInstruction}{questionGridInterviewerInstruction}</CardText>

					<CardTitle className="caption">Subquestion number / text :	</CardTitle>
					<CardText className="qtext"> {subQuestionText} </CardText>

					<CardTitle className="caption">Variables	{(result._source.relatedVariables!==undefined && result._source.relatedVariables?.length!==0 ) ? <a  >	&nbsp;&gt;&nbsp;<OverlayTrigger trigger="click" placement="left" overlay={variablePopover} rootClose><span title="Click to see related variables">Related variables</span></OverlayTrigger></a> : ""}</CardTitle>
					<CardText className="qtext">{variables}
					</CardText>

					<CardTitle className="caption">Answer codes	</CardTitle>
					<CardText className="qtext">{result._source.answerCodes ? result._source.answerCodes.map(c => <p key={"3" + c + "-" + props.cmpid}>{c} </p>) : ""}</CardText>

					<CardTitle className="caption">Response categories	</CardTitle>
					<CardText tag="ul" className="qtext">
						{(answerCategories && answerCategories.length > 0 ? answerCategories.map((ac, index) => <li key={"4" + ac + "-" + index}>{ac}</li>) : "")}
					</CardText>
				</CardBody>
			</Card>
		</Fragment>)
}
export default QuestionVerticalItem;