import './search.scss';
import './customStyle.scss';

import React, { Component } from 'react';
import EQBStudyDocumentationDownloadItem from "./studyDocumentationDownload-item";
import { Helmet } from 'react-helmet';

/* eslint no-console: 0 */
import {
	MatchQuery, SearchkitManager, SearchkitProvider, SearchBox, Hits, LayoutResults, Layout, LayoutBody
} from "searchkit";


import appConfig from 'app/config/constants';
const host = appConfig.ELASTICSEARCH_URL;
const searchkitManager = new SearchkitManager(host);

export class StudyDocumentationDownload extends Component {

	render() {
		const studyId = this.props.location.pathname.substr(this.props.location.pathname.lastIndexOf('/') + 1);

		searchkitManager.addDefaultQuery((query) => {
			return query.addQuery(MatchQuery("studyId", studyId))
		})
		return (
			<SearchkitProvider searchkit={searchkitManager}>
				<Layout>
					<Helmet>
						<title>Study detail</title>
					</Helmet>
					<SearchBox mod="searchBoxStudyDetail" />
					<LayoutBody>
						<LayoutResults>
							<Hits hitsPerPage={1} className="is-12" itemComponent={EQBStudyDocumentationDownloadItem} />
						</LayoutResults>
					</LayoutBody>
				</Layout>
			</SearchkitProvider>
		);
	}
}
export default StudyDocumentationDownload;