import './about.scss';

import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Alert } from 'reactstrap';

/* eslint no-console: 0  no-dupe-keys: 0 */

export type IAboutProp = StateProps;

export const About = (props: IAboutProp) => {
	return (
		<Container>
			<Row>

				<Col md="12">


					<h2>About the  Question Search
					</h2>
					DDI Search  addresses two main target groups:
					<p><strong>1. End Users</strong><br></br>
						<ol type="a">
							<li>
								<strong>Researchers</strong> are invited to browse and discover multilingual survey questions and question-related information to find questions on a particular topic that are relevant for their research.
						</li>
							<li>
								<strong>Questionnaire designers and translators</strong> interested in high-quality, tested questions or translations of questions can get a sense of how translators handled a particularly difficult translation issue for particular questions by comparing questions side-by-side.
						</li>
							<li>
								<strong>Survey methodologists</strong> who want to find out how questions on a particular topic change over time and who want explore study issues in question design and improvement, cannot only access and export survey questions but also study-related information and documentation.
						</li>
						</ol>
						<strong>2. Service Providers</strong><br></br>
					DDI Search  links existing European question data banks and offers them to promote their data within a single point of entry for survey questions from CESSDA Service Providers and beyond. Service Providers document and provide their metadata in DDI (<a href="https://ddialliance.org">https://ddialliance.org</a>) and make their metadata available via OAI-PMH (<a href="https://www.openarchives.org/pmh/">https://www.openarchives.org/pmh/</a>).
					</p>

					<p>
						DDI Search  is led by GESIS Leibniz Institute for the Social Sciences (Germany) and funded by the GESIS - Leibniz Institute for the Social Sciences.
</p>

				</Col>
			</Row>
		</Container>
	);
};

const mapStateToProps = storeState => ({
	account: storeState.authentication.account,
	isAuthenticated: storeState.authentication.isAuthenticated
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(About);
