import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';

/* eslint no-console: 0  no-dupe-keys: 0 */


export const Technical = () => {

	return (
		<Container>
			<Row>
				<Col md="12" >
					<h2>GESIS  Question Editor Technical information </h2>
					<div className="transition">
						foo bar
					</div>

					<h2>GESIS  Question Search Technical information </h2>
					<div className="transition">
						foo bar
					</div>

					<h2>GESIS DDI Converter Technical information </h2>
					<div className="transition">
						foo bar
					</div>
				</Col>
			</Row>
		</Container >
	);
};
export default Technical;

