import React from 'react';
import { Translate } from 'react-jhipster';

import { NavItem, NavLink, NavbarBrand } from 'reactstrap';
import { NavLink as Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import appConfig from 'app/config/constants';

export const BrandIcon = props => (
	<div {...props} className="brand-icon">
		<img src="content/images/gesis_Logo.svg" alt="Logo" />
	</div>
);

export const Brand = props => (
	<NavbarBrand tag={Link} to="/search" >
		<span className="brand-title">
			<Translate contentKey="global.title">ddisearch</Translate>
		</span>
		<span className="navbar-version">{appConfig.VERSION}</span>
	</NavbarBrand>
);

export const Home = props => (
	<NavItem>
		<NavLink tag={Link} to="/" className="d-flex align-items-center">
			<FontAwesomeIcon icon="home" />
			<span>
				<Translate contentKey="global.menu.home">Home</Translate>
			</span>
		</NavLink>
	</NavItem>
);
export const Intro = props => (
	<NavItem>
		<NavLink tag={Link} to="/apps" className="d-flex align-items-center">
			<FontAwesomeIcon icon="project-diagram" />
			<span>
				<Translate contentKey="global.menu.apps">Apps</Translate>
			</span>
		</NavLink>
	</NavItem>
);

export const About = props => (
	<NavItem>
		<NavLink tag={Link} to="/about" className="d-flex align-items-center">
			<span>About</span>
		</NavLink>
	</NavItem>
);
export const Help = props => (
	<NavItem>
		<NavLink tag={Link} to="/help" className="d-flex align-items-center">
			<span>Help</span>
		</NavLink>
	</NavItem>
);

export const Search = props => (
	<NavItem>
		<NavLink tag={Link} to="/search" className="d-flex align-items-center">
			<FontAwesomeIcon icon="search" />
			<span>Questions</span>
		</NavLink>
	</NavItem>
); 
export const StudySearch = props => (
	<NavItem>
		<NavLink tag={Link} to="/studysearch" className="d-flex align-items-center">
			<FontAwesomeIcon icon="search" />
			<span>Studies</span>
		</NavLink>
	</NavItem>
); 
