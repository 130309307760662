import 'react-toastify/dist/ReactToastify.css';
import './app.scss';
import {} from 'popper.js';

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Card } from 'reactstrap';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { hot } from 'react-hot-loader';
import { Container, Row, Col, Alert } from 'reactstrap';

import { IRootState } from 'app/shared/reducers';
import { getSession } from 'app/shared/reducers/authentication';
import { getProfile } from 'app/shared/reducers/application-profile';
import { setLocale } from 'app/shared/reducers/locale';
import Header from 'app/shared/layout/header/header';
import Footer from 'app/shared/layout/footer/footer';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import ErrorBoundary from 'app/shared/error/error-boundary';
import { AUTHORITIES } from 'app/config/constants';
import AppRoutes from 'app/routes';
import ComparisonProvider from 'app/modules/context/ComparisonProvider';
import BasketProvider from 'app/modules/context/BasketProvider';
import AuthProvider from 'app/modules/context/AuthProvider';
import SearchProvider from 'app/modules/context/SearchProvider';

const baseHref = document
	.querySelector('base')
	.getAttribute('href')
	.replace(/\/$/, '');

export interface IAppProps extends StateProps, DispatchProps { }
export const App = (props: IAppProps) => {
	useEffect(() => {
		props.getSession();
		props.getProfile();
	}, []);

	const paddingTop = '0px';
	return (
		<ComparisonProvider>
			<SearchProvider>
				<BasketProvider>
					<AuthProvider>
						<Router basename={baseHref}>
							<div className="app-container" style={{ paddingTop }}>
								<ToastContainer position={toast.POSITION.TOP_LEFT} className="toastify-container" toastClassName="toastify-toast" />
								<ErrorBoundary>
									<Header
										isAuthenticated={props.isAuthenticated}
										isAdmin={props.isAdmin}
										currentLocale={props.currentLocale}
										onLocaleChange={props.setLocale}
										ribbonEnv={props.ribbonEnv}
										isInProduction={props.isInProduction}
										isSwaggerEnabled={props.isSwaggerEnabled}
									/>
								</ErrorBoundary>
								<div className="container-fluid view-container" id="app-view-container">
									
									<Card className="jh-card">
										<ErrorBoundary>
											<AppRoutes />
										</ErrorBoundary>
									</Card>
								</div>
							</div>
							<Footer />
						</Router>
					</AuthProvider>
				</BasketProvider>
			</SearchProvider >
		</ComparisonProvider >
	);
};

const mapStateToProps = ({ authentication, applicationProfile, locale }: IRootState) => ({
	currentLocale: locale.currentLocale,
	isAuthenticated: authentication.isAuthenticated,
	isAdmin: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.ADMIN]),
	ribbonEnv: applicationProfile.ribbonEnv,
	isInProduction: applicationProfile.inProduction,
	isSwaggerEnabled: applicationProfile.isSwaggerEnabled
});

const mapDispatchToProps = { setLocale, getSession, getProfile };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;


export default connect(mapStateToProps, mapDispatchToProps)(hot(module)(App));



/* one tab variant: const WarningComponent = () => <div>Sorry! You can only have this application opened in one tab</div>  export default connect(mapStateToProps, mapDispatchToProps)(hot(module)(withOneTabEnforcer({appName: "euro-question-bank", OnlyOneTabComponent: WarningComponent})(App))); */