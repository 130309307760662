import React from 'react';
import { Translate, translate } from 'react-jhipster';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Alert, Row, Col } from 'reactstrap';
import { AvForm, AvField, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Link } from 'react-router-dom';

export interface ILoginModalProps {
	showModal: boolean;
	loginError: boolean;
	handleLogin: Function;
	handleClose: Function;
}

class LoginModal extends React.Component<ILoginModalProps> {
	handleSubmit = (event, errors, { username, password, rememberMe }) => {
		const { handleLogin } = this.props;
		handleLogin(username, password, rememberMe);
	};

	render() {
		const { loginError, handleClose } = this.props;

		return (
			<Modal isOpen={this.props.showModal} toggle={handleClose} backdrop="static" id="login-page" autoFocus={false}>
				<AvForm onSubmit={this.handleSubmit}>
					<ModalHeader id="login-title" toggle={handleClose}>
						<Translate contentKey="login.title">Sign in</Translate>
					</ModalHeader>
					<ModalBody>
						<Row>
							<Col md="11">
								{loginError ? (
									<Alert color="danger">
										<Translate contentKey="login.messages.error.authentication">
											<strong>Failed to sign in!</strong> Please check your credentials and try again.
                    </Translate>
									</Alert>
								) : null}
							</Col>
							<Col md="11">
								<AvField className="loginForm"
									name="username"
									label={translate('global.form.username.label')}
									placeholder={translate('global.form.username.placeholder')}
									required
									errorMessage="Username cannot be empty!"
									autoFocus
								/>
								<AvField className="loginForm"
									name="password"
									type="password"
									label={translate('login.form.password')}
									placeholder={translate('login.form.password.placeholder')}
									required
									errorMessage="Password cannot be empty!"
								/>
								<AvGroup check inline>
									<Label className="form-check-label">
										<AvInput type="checkbox" name="rememberMe" /> <Translate contentKey="login.form.rememberme">Remember me</Translate>
									</Label>
								</AvGroup>
							</Col>
						</Row>
						<Row>
							<Col md="5">
								<Alert color="warning">
									<Translate contentKey="login.password.forgot">Did you forget your password?</Translate>
									<br /><Link to="/account/reset/request">Reset it here</Link>
								</Alert>
							</Col>
							<Col md="5">
								<Alert color="warning">
									<Translate contentKey="global.messages.info.register.noaccount">You don&apos;t have an account yet?</Translate>
									<br /><Link to="/account/register">
										<Translate contentKey="global.messages.info.register.link">Register a new account</Translate>
									</Link>
								</Alert>
							</Col>
						</Row>
					</ModalBody>
					<ModalFooter>
						<Button color="secondary" onClick={handleClose} tabIndex="1">
							<Translate contentKey="entity.action.cancel">Cancel</Translate>
						</Button>{' '}
						<Button color="primary" type="submit">
							<Translate contentKey="login.form.button">Sign in</Translate>
						</Button>
					</ModalFooter>
				</AvForm>
			</Modal>
		);
	}
}

export default LoginModal;
