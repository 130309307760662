import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { Translate, ICrudGetAction, ICrudDeleteAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IComparison } from 'app/shared/model/comparison.model';
import { IRootState } from 'app/shared/reducers';
import { getEntity, deleteEntity } from './comparison.reducer';
/* eslint no-console: 0  no-dupe-keys: 0 */

export interface IComparisonDeleteDialogProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> { }

export const ComparisonDeleteDialog = (props: IComparisonDeleteDialogProps) => {
	useEffect(() => {
		props.getEntity(props.match.params.id);
		console.info("");
	}, []);

	const handleClose = () => {
		props.history.push('/comparison/matrix/');
	};

	useEffect(() => {
		if (props.updateSuccess) {
			handleClose();
			const el = document.getElementById("comparisonBadge");

			const p: any = Promise.resolve(props.getEntity(props.match.params.id));
			p.then(v => {
				console.info(v.action.payload.headers.itemsincomparison);
				if (v.action.payload.headers.itemsincomparison === "0") {
					el.innerHTML = "";
				} else {
					el.innerHTML = this.state.headers.itemsincomparison;
				}
			});
		}
	}, [props.updateSuccess]);

	const confirmDelete = () => {
		props.deleteEntity(props.comparisonEntity.id);
	};

	const { comparisonEntity } = props;
	return (
		<Modal isOpen toggle={handleClose}>
			<ModalHeader toggle={handleClose}>
				<Translate contentKey="entity.delete.title">Confirm delete operation</Translate>
			</ModalHeader>
			<ModalBody id="ddiSearchApp.comparison.delete.question">
				<Translate contentKey="ddiSearchApp.comparison.delete.question" interpolate={{ id: comparisonEntity.id }}>
					Are you sure you want to delete this Comparison?
        </Translate>
			</ModalBody>
			<ModalFooter>
				<Button color="secondary" onClick={handleClose}>
					<FontAwesomeIcon icon="ban" />
          &nbsp;
          <Translate contentKey="entity.action.cancel">Cancel</Translate>
				</Button>
				<Button id="jhi-confirm-delete-comparison" color="danger" onClick={confirmDelete}>
					<FontAwesomeIcon icon="trash" />
          &nbsp;
          <Translate contentKey="entity.action.delete">Delete</Translate>
				</Button>
			</ModalFooter>
		</Modal>
	);
};

const mapStateToProps = ({ comparison }: IRootState) => ({
	comparisonEntity: comparison.entity,
	updateSuccess: comparison.updateSuccess
});

const mapDispatchToProps = { getEntity, deleteEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ComparisonDeleteDialog);
