import { connect } from 'react-redux';
import React, { useContext, useEffect } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { NavLink as Link } from 'react-router-dom';
import Badge from 'react-bootstrap/Badge';
import BasketContext from 'app/modules/context/BasketContext';
import AuthContext from 'app/modules/context/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import { isInMyBasket } from 'app/entities/basket/basket.reducer';

/* eslint no-console: 0 */

function BasketMenuButton(props) {


	const basketContext: any = useContext(BasketContext);
	const authContext: any = useContext(AuthContext);
	
	
	useEffect(() => {
		const promise: any = isInMyBasket(0);
		const payload = promise.payload;
		payload.then(v => {
			if (authContext) {
				authContext.update({ isAdmin: v.headers.isadmin });
				authContext.update({ isAuthenticated: v.headers.isauthenticated });
			}
			if (basketContext) {
				basketContext.update({ itemsinbasket: v.headers.itemsinbasket });
			}
		});
	}, []);



	return (

			<NavItem icon="asterisk" key="basketMenuButton" >
				<NavLink tag={Link} to="/basket" className="d-flex align-items-center">
					<FontAwesomeIcon icon="shopping-basket" color="#fff" />&nbsp;Basket <Badge id="basketBadge"> {basketContext.itemsinbasket}</Badge>
				</NavLink>
			</NavItem>
	)
}


const mapStateToProps = (storeState) => ({
	headers: storeState.basket.headers,
});
const mapDispatchToProps = {
	isInMyBasket,
};
export default connect(mapStateToProps, mapDispatchToProps)(BasketMenuButton);