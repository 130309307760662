import './home.scss';

import React from 'react';
import { Link } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Container, Row, Col, Alert } from 'reactstrap';

import * as componentUtility from "../../search/componentUtility"
import {
	PageSizeSelector, InputFilter,
	Toggle, SortingSelector, BoolShould, TermQuery, FilteredQuery,
	HitsStats, SearchkitManager, GroupedSelectedFilters, ResetFilters, RangeFilter, SearchkitProvider, SideBar, SearchBox, Hits, RefinementListFilter, LayoutResults, Layout, LayoutBody, Pagination, PaginationSelect
} from "searchkit";

import appConfig from 'app/config/constants';
import { IRootState } from 'app/shared/reducers';
/* eslint no-console: 0  no-dupe-keys: 0 */

export type IHomeProp = StateProps;

export const Home = (props: IHomeProp) => {
	
	const { account } = props;
	return (
		<Container>
			<Row>

				<Col md="12">
					<a href="/">
						<img src="content/images/gesis_Logo_lang.svg" width="100%" alt="Logo" />
					</a>


					<h2>
						<Translate contentKey="home.title">Welcome to the  Question Search !</Translate>
					</h2>
					<p className="lead">
						Are you looking for fielded survey questions to inspire your own questionnaire or research?
        			</p>
					<p className="lead">Do you want to locate data on specific topics?
					</p>
					<p className="lead">Looking for questionnaire translations?
					</p>
					<p className="lead">&nbsp;
					</p><p className="lead">Then the  Question Search  may be the right tool for you!
					</p><p className="lead">Just register or login and start searching!
					</p>
				</Col>
			</Row>
			<Row><Col md="12">
				{account && account.login ? (
					<div>
						<Alert color="success">
							<Translate contentKey="home.logged.message" interpolate={{ username: account.login }}>
								You are logged in as user {account.login}.
              </Translate>
						</Alert>
					</div>
				) : (
						<div>
							<Alert color="info">
								<Translate contentKey="global.messages.info.register.noaccount">You do not have an account yet?</Translate>&nbsp;
             						 <Link to="/account/register" className="alert-link">
									<Translate contentKey="global.messages.info.register.link">Register a new account</Translate>
								</Link>
							</Alert>
						</div>
					)}

			</Col>

			</Row>
		</Container>
	);
};

const mapStateToProps = storeState => ({
	account: storeState.authentication.account,
	isAuthenticated: storeState.authentication.isAuthenticated
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(Home);
