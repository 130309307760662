import React from 'react';
import { Container, Row, Col } from 'reactstrap';
/* eslint no-console: 0  no-dupe-keys: 0 */
export const Converter = () => {
	return (
		<Container>
			<Row>
				<Col md="12" >
					<h2>GESIS DDI Converter			</h2>
						With DDI Converter, research dara archives can quickly convert data documentation from legacy version to DDI 3.2
				</Col>
			</Row>
		</Container>
	);
};
export default Converter;

