import './search.scss';
import './customStyle.scss';

import { Link } from 'react-router-dom';
import React, { useContext, useRef, useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import BootstrapTable from 'react-bootstrap-table-next';
import * as componentUtility from "./componentUtility";
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { Button } from 'reactstrap';


import * as ReactDOM from "react-dom";
import BasketButton from './basket-button';
import ComparisonButton from './comparison-button';
import AuthContext from 'app/modules/context/AuthContext';

/* eslint no-console: 0 */


import appConfig from 'app/config/constants';
const host = appConfig.ELASTICSEARCH_URL;

function QuestionHitItem(props) {

	const authContext = useContext(AuthContext);
	const langToggle = useRef();
	const [studyTitle, setStudyTitle] = useState([]);
	const [studyDescription, setStudyDescription] = useState([]);
	const [questionItemText, setQuestionItemText] = useState([]);
	const [questionGridText, setQuestionGridText] = useState([]);
	const [displayLang, setDisplayLang] = useState(props.preferredDisplayLanguage);
	const { result } = props;
	const rawJson = host + "/_doc/" + result._id;
	const [relatedVariables, setRelatedVariables] = useState([]);


	let effctvDsplyLng = props.preferredDisplayLanguage ? props.preferredDisplayLanguage : displayLang;
	const vcolumns = [{
		dataField: 'index',
		text: 'Order',
		sort: true
	}, {
		dataField: 'study',
		text: 'Study',
		sort: true
	}, {
		dataField: 'varr',
		text: 'Var name',
		sort: true
	}, {
		dataField: 'year',
		text: 'Study year',
		sort: true
	}, {
		dataField: 'yearLink',
		text: 'Search ',
		sort: false
	}];

	const variablePopover = (
		<Popover id="popover-varaibles" placement="top">
			<Popover.Title as="h3">Related variables</Popover.Title>
			<Popover.Content>
				<BootstrapTable keyField='index' data={relatedVariables} columns={vcolumns} />
			</Popover.Content>
		</Popover>
	);
	function bestMatchingLang(data, la, dropdown, override) {
		if (override) {
			la = dropdown;
		}
		if (data === undefined) return;
		let preSelLang = override ? dropdown : effctvDsplyLng;
		if (dropdown.length > 2 && dropdown.startsWith(preSelLang) && dropdown.endsWith(preSelLang)) {
			return dropdown;
		}
		if (preSelLang === undefined) {
			preSelLang = data.filter((item, index) => item.startsWith(la) && item.endsWith(la));
		}
		if (preSelLang === undefined) {
			preSelLang = data.filter((item, index) => item.startsWith(la));
		}
		if (preSelLang === undefined) {
			preSelLang = la;
		}
		return preSelLang;
	}

	const setdisplayLang = (event) => {
		effctvDsplyLng = bestMatchingLang(result._source.languagesCountries, effctvDsplyLng, event, true);
		console.info(effctvDsplyLng);
		console.info(displayLang);
	}

	function getQuestionGridText() {
		let questionGridText1 = undefined;
		if (result._source.questionGridTexts !== undefined) {
			if (result.highlight !== undefined) {
				questionGridText1 = result.highlight['questionGridTexts.translations.' + effctvDsplyLng];
				questionGridText1 = componentUtility.txt2Html(questionGridText1);
				if (questionGridText1 === undefined) {
					questionGridText1 = componentUtility.langHandler(result._source.questionGridTexts.translations, effctvDsplyLng);
				}
			}
			else {
				questionGridText1 = componentUtility.langHandler(result._source.questionGridTexts.translations, effctvDsplyLng);
			}
		}
		return questionGridText1 === undefined ? [] : questionGridText1;
	}

	function getQuestionItemText() {
		let questionItemText1 = undefined;
		if (result._source.questionItemTexts !== undefined) {
			if (result.highlight !== undefined) {
				questionItemText1 = result.highlight['questionItemTexts.translations.' + effctvDsplyLng];
				console.info(questionItemText1);
				questionItemText1 = componentUtility.txt2Html(questionItemText1);
				if (questionItemText1 === undefined) {
					questionItemText1 = componentUtility.langHandler(result._source.questionItemTexts.translations, effctvDsplyLng);
				}
			}
			else {
				questionItemText1 = componentUtility.langHandler(result._source.questionItemTexts.translations, effctvDsplyLng);
			}
		}
		return questionItemText1 === undefined ? [] : questionItemText1;
	}

	function getStudyDescriptions() {
		if (result._source.studyDescriptions !== undefined) {
			let studyDescription1 = undefined;
			studyDescription1 = componentUtility.langHandler(result._source.studyDescriptions.translations, effctvDsplyLng);
			return studyDescription1 === undefined ? [] : studyDescription1;
		}

	}


	function getStudyTitle() {
		if (result._source.studyTitles !== undefined) {
			let studyTitle1 = undefined;
			studyTitle1 = componentUtility.langHandler(result._source.studyTitles.translations, effctvDsplyLng);
			return studyTitle1 === undefined ? [] : studyTitle1;
		}

	}
	function getRelatedVariables() {

		if (result._source.relatedVariables !== undefined) {
			result._source.relatedVariables.map((ac, index) => {
				const yearLink = <a href={'/search?studyIDs[0]=' + ac.study + '&varNames[0]=' + ac.var}>	<FontAwesomeIcon icon="search" /></a>;
				const study = ac.study;
				const varr = ac.var;
				const year = ac.startEndYearStartYear;

				const v = { index, study, varr, year, yearLink };
				relatedVariables.push(v);
			});
		} else {
				setRelatedVariables([]);
			}
		
		
		console.error(relatedVariables);
		return relatedVariables;
	}

	function update() {
		console.info("");
		setStudyTitle(getStudyTitle())
		setStudyDescription(getStudyDescriptions())
		setQuestionItemText(getQuestionItemText())
		setQuestionGridText(getQuestionGridText())
		setRelatedVariables(getRelatedVariables())

	}

	const langSelector = (data) => {
		const ds = [];

		// flatten multiple
		data = data.filter((item, index) => data.indexOf(item) === index);
		data = data.sort();
		// find best match for preselection
		// exact? 
		if (data === undefined || data.length === 0) {
			return "";
		}
		if (data.length > 1) {
			ds.push(<option key={result._id + "-null"} value={effctvDsplyLng}>
				{componentUtility.langName(effctvDsplyLng)}</option>);
		}
		for (let i = 0; i < data.length; i++) {
			ds.push(<option key={result._id + "-" + data[i]} value={data[i]}>{componentUtility.langName(data[i])}</option>);
		}
		return <select
			className="btn btn-primary btn-sm langsel"
			ref={langToggle}
			selectedOptions={[effctvDsplyLng]}
			title={"Display fields in " + displayLang}
			onChange={e => { setdisplayLang(langToggle?.current.selectedOptions[0].value); update() }}  >
			{ds}
		</select>;

	}


	function getLanguages() {
		return langSelector(componentUtility.languagesOfItemAndGridTexts(result));
	}

	const langSwitch = getLanguages();
	effctvDsplyLng = bestMatchingLang(result._source.languagesCountries, effctvDsplyLng,
		langToggle?.current?.selectedOptions !== undefined && langToggle?.current?.selectedOptions[0] !== undefined ? langToggle?.current?.selectedOptions[0].value : "en");

	useEffect(() => {

		setStudyTitle(getStudyTitle())
		setStudyDescription(getStudyDescriptions())
		setQuestionItemText(getQuestionItemText())
		setQuestionGridText(getQuestionGridText())
		setRelatedVariables(getRelatedVariables())


	}, [result, displayLang])


	let codes = [];
	useEffect(() => {
		if (result._source.answerCategories?.translations !== undefined) {
			const langCntryCodes = Object.keys(result._source.answerCategories.translations)

			let selLang = effctvDsplyLng;
			if (!langCntryCodes.includes(effctvDsplyLng)) {
				selLang = langCntryCodes[0];
			}

			let repnsCatCodes = result._source.answerCategories.translations[selLang];
			if (repnsCatCodes?.length > 0) {
				repnsCatCodes.map((ac, index) => {
					if (ac.trim() !== "") {
						const arr = ac.split("|");
						const code = arr[0];
						const cat = arr[1];
						const v = { index, code, cat };
						if (code !== "") {
							codes.push(v);
						}
					}

				});
			} else {
				repnsCatCodes = [];
			}

			codes = repnsCatCodes;
		}
	}, [effctvDsplyLng, langSwitch]);

	const columns = [{
		dataField: 'index',
		text: 'Order',
		sort: true
	}, {
		dataField: 'code',
		text: 'Answer code',
		sort: true
	}, {
		dataField: 'cat',
		text: 'Response category',
		sort: true
	}];

	const responsePopover = (
		<Popover id="popover-responses" placement="auto">
			<Popover.Title as="h3">Response categories</Popover.Title>
			<Popover.Content>
				<BootstrapTable keyField='code' data={codes} columns={columns} />
			</Popover.Content>
		</Popover>
	);

	if (studyTitle === undefined) { setStudyTitle(["no title"]) }
	let adminLinks = "";

	if (authContext.isAdmin === "true") {
		adminLinks = <span key={result._id + 'cb'}>
			<a target="json" href={rawJson}>&nbsp;json<br /></a></span>
	} else {
		adminLinks = "";
	}

	const popover = (
		<Popover id="popover-basic" placement="auto">
			<Popover.Title as="h3">License</Popover.Title>
			<Popover.Content>
				{studyDescription}
			</Popover.Content>
		</Popover>
	);

	return (<div data-qa="hit" className="list_hit" id={result._id}>
		<Row>
			<Col sm="12">
				<BasketButton qid={result._id} size="sm" />&nbsp;
				<ComparisonButton qid={result._id} size="sm" />&nbsp;
				{langSwitch}
			</Col>

		</Row>
		<Row>
			<Col sm="12"><strong>Question Text</strong> {adminLinks} <br></br>
				{questionItemText.length === 0 && questionGridText.length === 0 ? "No text available" : ""}{questionItemText}{questionGridText}<br />{studyTitle}
			</Col>
		</Row>
		<Row >

			<Col sm="12" id={"direct_" + result._id}>
				<a className="btn btn-primary btn-small" role="button"
					href={`/question/version/${result._id}`}>
					Question</a>
				<a
					href={`/study/${result._source.studyId}`}
					className="btn btn-primary btn-small" role="button" >
					Study</a>

				{result._source.studyDocumentation !== undefined ? <a color="link" href={`/study/documentation/download/${result._source.studyId}`} className="btn btn-primary btn-small" role="button" >
					Documentation </a> : ""}

				{result._source.answerCategories ? <a className="btn btn-primary btn-small" role="button">	<OverlayTrigger trigger="click" placement="right" overlay={responsePopover} rootClose><span title="Click to see response categories">Responses</span></OverlayTrigger></a> : ""}
				{(result._source.relatedVariables!==undefined && result._source.relatedVariables?.length!==0 )? <a className="btn btn-primary btn-small" role="button">	<OverlayTrigger trigger="click" placement="left" overlay={variablePopover} rootClose><span title="Click to see related variables">Variables</span></OverlayTrigger></a> : ""}
			</Col>
		</Row>
	</div >)


}
export default QuestionHitItem;

