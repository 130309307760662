import './search.scss';
import './customStyle.scss';

import React, { useContext, useState, useEffect, useRef } from 'react';
import { Row, Col, Container } from 'reactstrap';
import * as componentUtility from "../search/componentUtility";
import ResultListComponent from "./study-resultList-component.jsx";
import { Helmet } from "react-helmet";
import ReactTooltip from 'react-tooltip';
/* eslint  no-console: 0 */

import {
	PageSizeSelector, InputFilter,
	Toggle, SortingSelector, NoHits, BoolShould, TermQuery, FilteredQuery,
	HitsStats, SearchkitManager, GroupedSelectedFilters, ResetFilters, RangeFilter, SearchkitProvider, SideBar, SearchBox, Hits, RefinementListFilter, LayoutResults, Layout, LayoutBody, Pagination, PaginationSelect
} from "searchkit";

import appConfig from 'app/config/constants';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SearchContext from 'app/modules/context/SearchContext';
import AuthContext from 'app/modules/context/AuthContext';
import InputLabel from 'app/search/inputLabel'


const host = appConfig.ELASTICSEARCH_URL_STUDY;
const searchkitManager = new SearchkitManager(host, { timeout: 20000, createBrowserHistory: true });


const studyTitlesPaths = ["studyTitles.translations"];


const ShortRefinementOption = (props) => (
	<li className={props.bemBlocks.option().state({ selected: props.selected }).mix(props.bemBlocks.container("item"))} onClick={props.onClick}>
		<div className={props.bemBlocks.option("text")} title={props.label}>{props.label.length >= 36 ? props.label.substring(0, 36) : props.label}</div>
		<div className={props.bemBlocks.option("count")}>{props.count}</div>
	</li>
)

function Search(props) {
	const sbox = useRef();
	const authContext = useContext(AuthContext);
	const searchContext = useContext(SearchContext);
	const [searchLang, setSearchLang] = useState("all");
	const searchLangSwitch = useRef();

	const searchLangSelector = () => {
		const ds = [];
		const data = componentUtility.langCodes;
		for (let i = 0; i < data.length; i++) {
			ds.push(<option key={data[i]} value={data[i]}>{componentUtility.langName(data[i]) + " [" + data[i] + "]"}</option	>);
		}
		return <select className="btn btn-primary" id='searchLangSwitch' ref={searchLangSwitch}
			name='searchLangSwitch' title={"Search fields in " + searchLang} value={searchLang}
			onChange={e => { setSearchLang(e.currentTarget.value) }}  >{ds}</select>;
	}

	const nested = (query, options) => {

		if (!query) return {}

		const curSearchLang = searchLangSwitch?.current?.selectedOptions[0].value;

		setSearchLang(curSearchLang);
		if (options.fields === undefined) {
			const fields = [];
			if (curSearchLang !== "all") {
				fields.push("all." + curSearchLang);
			} else {
				fields.push("all.all");
				for (let i = 0; i < componentUtility.langCodes.length; i++) {
					const field = "all." + componentUtility.langCodes[i];
					fields.push(field);
				}
			}
			fields.push("studyId");
			let json = {};
			json = {
				"bool": {
					"should": [
						{ "multi_match": { query, fields } }
					]
				}
			}

			return json;
		} else {
			const fields = [];

			const newOptionsFields = []
			options.fields.map(f => {
				const s = f.replace(".undefined", "")
				newOptionsFields.push(s)
			})
			if (newOptionsFields.length === 1 && newOptionsFields[0] === "_all") {
				options.fields = ["all"]
			} else {
				options.fields = newOptionsFields;

			}

			if (curSearchLang !== "all") {
				for (let i = 0; i < componentUtility.langCountryCodes.length; i++) {
					if (componentUtility.langCountryCodes[i].startsWith(curSearchLang)) {
						for (let j = 0; j < options.fields.length; j++) {
							const field = options.fields[j] + '.' + componentUtility.langCountryCodes[i];
							fields.push(field);
						}
					}

				}
			}
			else {
				for (let i = 0; i < componentUtility.langCountryCodes.length; i++) {
					for (let j = 0; j < options.fields.length; j++) {
						const field = options.fields[j] + '.' + componentUtility.langCountryCodes[i];
						fields.push(field);
					}
				}
			}
			fields.push("studyId");
			fields.push("studyTitleSortableEn.keyword");
			let json = {};
			json = {
				"bool": {
					"should": [
						{ "multi_match": { query, fields } }
					]
				}
			}
			return json;
		}

	}



	useEffect(
		() => {
			console.info("search")
			/*	searchkitManager.performSearch(); */
		}, [authContext.isAdmin, searchLang]);
	/*	custom highlighter disable for performance reason  */
	const chl = {
		"fields": {
			"*": {}
		},
		"fragment_size": 400
	}
	const searchLangSelect = searchLangSelector();


	const inLanguage = (fieldArr) => {
		const i18nFieldArr = [];
		fieldArr.map(f => {
			i18nFieldArr.push(f + "." + searchLangSwitch?.current?.selectedOptions[0].value);
		});
		return i18nFieldArr;
	}

	const removalFn = searchkitManager.addResultsListener((results) => {
		const ids = results.hits.hits.map(function(hit) {
			return hit._id;
		}).join(",")
		searchContext.update({ hitIds: ids });
		removalFn();
	})


	return (
		<SearchkitProvider searchkit={searchkitManager}>
			<Layout size="s" className="search" id="search">
				<Helmet>
					<title>Question Search</title>

				</Helmet>
				<Container>
					<Row>
						<Col md="3">
							<a href="/">
							</a>
						</Col>
						<Col md="6">
							<SearchBox autofocus={true} searchOnChange={true} searchThrottleTime={400} queryBuilder={nested} ref={sbox} />
							<ResetFilters translations={{ "reset.clear_all": "Clear search" }} options={{ query: true, filter: true, pagination: true }} />

						</Col>
						<Col md="3" className="searchmod">

							{searchLangSelect}
						</Col>

					</Row>
				</Container>
				<Container>
					<LayoutBody className="row">
						<SideBar className="col-md-3">
							<HitsStats />
							<h6>Page size </h6>
							<PageSizeSelector options={[5, 10, 25]} listComponent={Toggle} />
							<h6>Sort by</h6>
							<SortingSelector options={[
								{ label: "Relevance", field: "_score", order: "desc", defaultOption: true },
								{ label: "Date of collection (newest)", field: "studyPublicationYear.keyword", order: "desc" },
								{ label: "Date of collection (oldest)", field: "studyPublicationYear.keyword", order: "asc" },
								{
									label: "Study titles A-Z", key: "titleAZ", order: "desc",
									field: "studyTitleSortableEn.keyword"
								}, {
									label: "Study titles Z-A", key: "titleZA", order: "asc",
									field: "studyTitleSortableEn.keyword"
								},
							]}
							/>
							<h6>Selected filters</h6>
							<GroupedSelectedFilters />

							<RefinementListFilter
								id="language"
								title={<div><span className="sk-panel__header">Interview language</span>
									<a data-tip='languageTip' data-for='languageTip'>
										&nbsp;<FontAwesomeIcon icon="question-circle" />
									</a>
									<ReactTooltip className="clickTip" id='languageTip'>
										Language of the interview							</ReactTooltip></div>}
								field={'languagesCountries.keyword'}
								size={3} operator={'OR'} orderKey="_key" orderDirection="asc" />

							<h6><InputLabel txt={'Study titles '} lng={searchLangSwitch?.current?.selectedOptions[0].value} /></h6>
							<InputFilter
								id="studyTitlesPaths"
								title={"Study titles "}
								placeholder="Search in study titles"
								searchOnChange={true}
								queryBuilder={nested}
								queryFields={inLanguage(studyTitlesPaths)}
							/>

							<RefinementListFilter
								id="series"

								title={<div><span className="sk-panel__header">Series</span>
									<a data-tip='seriesTip' data-for='seriesTip'>
										&nbsp;<FontAwesomeIcon icon="question-circle" />
									</a>
									<ReactTooltip className="clickTip" id='seriesTip'>
										The series/study group the study or question appeared in.
									</ReactTooltip></div>}
								field={'studyGroupsRefs.groupId.keyword'}
								size={3} orderDirection="asc" orderKey="_key"
								operator={'OR'} />

							<RefinementListFilter
								id="studyGeographicLocationName"

								title={<div><span className="sk-panel__header">Country</span>
									<a data-tip='studyGeographicLocationNameTip' data-for='studyGeographicLocationNameTip'>
										&nbsp;<FontAwesomeIcon icon="question-circle" />
									</a>
									<ReactTooltip className="clickTip" id='studyGeographicLocationNameTip'>
										The country in which the study took place.
									</ReactTooltip>
								</div>}
								field={'studyGeographicLocationName.translations.en.keyword'}
								size={3} operator={'OR'} />

							<RefinementListFilter
								id="modeOfCollectionType"

								title={<div><span className='sk-panel__header'>Data collection mode</span>
									<a data-tip='modeOfCollectionTypeTip' data-for='modeOfCollectionTypeTip'>
										&nbsp;<FontAwesomeIcon icon='question-circle' />
									</a>
									<ReactTooltip className='clickTip' data-place={top} delayHide={250} data-html={true} id='modeOfCollectionTypeTip'>
										<span>The procedure, technique, or mode of inquiry used to obtain the data. <br />
											If you are not sure which collection mode to choose, <br />
											please see <a href='https://ddialliance.org/Specification/DDI-CV/ModeOfCollection_2.0.html'>DDI Controlled Vocabulary for further information.</a> <br />
										</span>
									</ReactTooltip></div>}

								field={'modeOfCollectionType.keyword'}
								itemComponent={ShortRefinementOption}
								size={3} operator={'OR'} orderKey="_key" orderDirection="asc" />


							<RangeFilter field="startEndYear.startYear"
								id='startYear'
								min={1950} max={2022}
								showHistogram={true}

								title={<div><span className="sk-panel__header">Collection year (first)</span>
									<a data-tip='startYearTip' data-for='startYearTip'>
										&nbsp;<FontAwesomeIcon icon="question-circle" />
									</a>
									<ReactTooltip className="clickTip" id='startYearTip'>
										The start of the period, in years, when the data were collected.
									</ReactTooltip></div>}
							/>
							<RangeFilter field="startEndYear.endYear"
								id='endYear'
								min={1950} max={2022}
								showHistogram={true}

								title={<div><span className="sk-panel__header">Collection year (last)</span>
									<a data-tip='endYearTip' data-for='endYearTip'>
										&nbsp;<FontAwesomeIcon icon="question-circle" />
									</a>
									<ReactTooltip className="clickTip" id='endYearTip'>
										The end of the period, in years, when the data were collected.
									</ReactTooltip></div>} />
							<RefinementListFilter
								id="publisherName"
								title="Study publisher"
								field="publisherNameSortableEn.keyword"
								size={3} />
							<RefinementListFilter
								id="samplingProcedureType"
								title="Sampling procedure"
								field={'samplingProcedureType.keyword'}
								itemComponent={ShortRefinementOption}
								size={3} operator={'OR'} orderKey="_key" orderDirection="asc" />

							<RefinementListFilter
								id="topicalCoverage"
								title="Topical coverage	"
								operator={'OR'}
								field={'studyTopicalCoverageSortableEn.keyword'}
								itemComponent={ShortRefinementOption}
								size={3} orderDirection="asc" orderKey="_key" />

							<RefinementListFilter
								id="timeMethodType"

								title={<div><span className="sk-panel__header">Time method</span>
									<a data-tip='timeMethodTypeTip' data-for='timeMethodTypeTip'>
										&nbsp;<FontAwesomeIcon icon="question-circle" />
									</a>
									<ReactTooltip className="clickTip" data-place={top} delayHide={250} data-html={true} id='timeMethodTypeTip'>
										<span>Describes the time dimension of the data collection. <br />
											See <a href="https://ddialliance.org/Specification/DDI-CV/TimeMethod_1.0.0.html">here </a> for details.</span>
									</ReactTooltip></div>}
								field={'timeMethodType.keyword'}
								itemComponent={ShortRefinementOption}
								size={3} operator={'OR'} orderKey="_key" orderDirection="asc" />



							<RefinementListFilter
								id="studyPIDs"
								title="Study PID	"
								field={'studyPIDs.keyword'}
								size={3} orderDirection="asc"
								operator={'OR'} />
						</SideBar>
						<LayoutResults className="col-md-9">
							<Hits hitsPerPage={5} mod="sk-hits-list" className="column" sourceFilter={{ 'excludes': ['answerCategories.translations.*'] }}
								listComponent={<ResultListComponent />} />
							<Row>
								<Col md="12">
									<Pagination pageScope={5} showLast={true} showNumbers={true} />
								</Col>
							</Row>
							<NoHits
								translations={{
									"NoHits.NoResultsFound": "No studies found for {query}",
									"NoHits.DidYouMean": "Search for {suggestion}",
									"NoHits.SearchWithoutFilters": "Search for {query} without filters"
								}} suggestionsField="studyTitleSortableEn.keyword" />
						</LayoutResults>
					</LayoutBody>
				</Container>
			</Layout>
		</SearchkitProvider>
	);
}

export default Search;
