import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { Translate, ICrudGetAction, ICrudDeleteAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IComparison } from 'app/shared/model/comparison.model';
import { IRootState } from 'app/shared/reducers';
import { getEntities, deleteEntity } from './comparison.reducer';
/* eslint no-console: 0  no-dupe-keys: 0 */

export interface IComparisonDeleteDialogProps extends StateProps, DispatchProps, RouteComponentProps<{}> { }

export const ComparisonDeleteAllDialog = (props: IComparisonDeleteDialogProps) => {
	useEffect(() => {
		const z = props.getEntities();
		const p: any = Promise.resolve(z);
		
		p.then(v => {
			console.log(v.action.payload.data);
			v.action.payload.data.map((comparison, i) =>
			{
				console.info(i + ":" + comparison.id);
				props.deleteEntity(comparison.id)
			});
			const el =document.getElementById("comparisonBadge");
			el.innerHTML="0";
		});
		console.info();
	}, []);

	const handleClose = () => {
		props.history.push('/comparison/matrix/');
	};

	useEffect(() => {
		if (props.updateSuccess) {
			handleClose();
		}
	}, [props.updateSuccess]);

	const confirmDeleteAll = () => {
		props.deleteEntity(props.comparisonEntity.id);
	};

	const { comparisonEntity } = props;
	return (
		<Modal isOpen toggle={handleClose}>
			<ModalHeader toggle={handleClose}>
				<Translate contentKey="entity.delete.title">Confirm delete all operation</Translate>
			</ModalHeader>
			<ModalBody id="ddiSearchApp.comparison.delete.question">
				Are you sure you want to delete all questions from comparison
			</ModalBody>
			<ModalFooter>
				<Button color="secondary" onClick={handleClose}>
					<FontAwesomeIcon icon="ban" />
          &nbsp;
          <Translate contentKey="entity.action.cancel">Cancel</Translate>
				</Button>
				<Button id="jhi-confirm-delete-comparison" color="danger" onClick={confirmDeleteAll}>
					<FontAwesomeIcon icon="trash" />
          &nbsp;
          <Translate contentKey="entity.action.delete">Delete</Translate>
				</Button>
			</ModalFooter>
		</Modal>
	);
};

const mapStateToProps = ({ comparison }: IRootState) => ({
	comparisonEntity: comparison.entity,
	updateSuccess: comparison.updateSuccess
});

const mapDispatchToProps = { getEntities, deleteEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ComparisonDeleteAllDialog);
