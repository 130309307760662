import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, ICrudGetAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './basket.reducer';
import { IBasket } from 'app/shared/model/basket.model';


export interface IBasketDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> { }

export const BasketDetail = (props: IBasketDetailProps) => {
	useEffect(() => {
		props.getEntity(props.match.params.id);
	}, []);

	const { basketEntity } = props;
	return (
		<Fragment>
			<Row>
				<Col md="12">
					<h2>
						<Translate contentKey="ddiSearchApp.basket.detail.title">Basket</Translate>
						[<b>{basketEntity.id}</b>]
        			</h2>
					<dl className="jh-entity-details">
						<dt>
							<span id="qRef">
								<Translate contentKey="ddiSearchApp.basket.qRef">Q Ref</Translate>
							</span>
						</dt>
						<dd>{basketEntity.qRef}</dd>
						<dt>
							<Translate contentKey="ddiSearchApp.basket.user">User</Translate>
						</dt>
						<dd>{basketEntity.user ? basketEntity.user.login : ''}</dd>
					</dl>
					<Button tag={Link} to="/basket" replace color="info">
						<FontAwesomeIcon icon="arrow-left" />{' '}
						<span className="d-none d-md-inline">
							<Translate contentKey="entity.action.back">Back</Translate>
						</span>
					</Button>
					<Button
						tag={Link}
						to={`/basket/${basketEntity.id}/delete`}
						color="danger"	>
						<FontAwesomeIcon icon="trash" />{' '}
						<span className="d-none d-md-inline">
							<Translate contentKey="entity.action.delete">Delete</Translate>
						</span>
					</Button>
				</Col>
			</Row>
			<Row>
				<Col md="12">
					<a
						href={`/api/baskets/pdf/my/${basketEntity.qRef}`}
					>
						<FontAwesomeIcon icon="file" color="white" />
										Get question as PDF
									</a>
				</Col>
			</Row>
		</Fragment>
	);
};

const mapStateToProps = ({ basket }: IRootState) => ({
	basketEntity: basket.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(BasketDetail);
