import './search.scss';
import './customStyle.scss';

import { connect } from 'react-redux';
import React, {  Fragment, useContext } from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import { getEntity, updateEntity, createEntity, isInMyBasket, toggleInMyBasket, reset, resetBasket } from '../entities/basket/basket.reducer';

import { Link } from 'react-router-dom';

import BasketContext from 'app/modules/context/BasketContext';
import AuthContext from 'app/modules/context/AuthContext';


/* eslint no-console: 0 */


const popover = (
	<Popover id="popover-basic" placement="auto">
		<Popover.Title as="h3">Account required</Popover.Title>
		<Popover.Content>To use the export basket function
		<Link to="/login"> login </Link> or <Link to="/account/register"> register a new account.</Link>
		</Popover.Content>
	</Popover>
);

const mapStateToProps = (storeState) => ({
	basketEntity: storeState.basket.entity,
	updateSuccess: storeState.basket.updateSuccess
});

const mapDispatchToProps = {
	getEntity,
	updateEntity,
	createEntity,
	isInMyBasket,
	resetBasket,
	toggleInMyBasket,
	reset
};

function BasketButton(props) {

	const basketContext = useContext(BasketContext);
	const authContext = useContext(AuthContext);

	const toggle = (qid) => {
		const togglePromise = toggleInMyBasket(qid).payload;

		togglePromise.then(v => {
			basketContext.update({ itemsidsinbasket: v.headers.itemsidsinbasket })
			basketContext.update({ itemsinbasket: v.headers.itemsinbasket })
		});
	};

	return (

		authContext.isAuthenticated==="true" ?
			<Fragment key="{qid +'bb'}"  >
				{basketContext.itemsidsinbasket !== undefined && basketContext.itemsidsinbasket.includes(props.qid) === true ?
					<Button onClick={() => toggle(props.qid)} title="remove from basket" size={props.size} >
						<FontAwesomeIcon id={"icon" + props.qid} icon="shopping-basket" color="#17a2b8" />
					</Button>
					:
					<Button onClick={() => toggle(props.qid)} title="add to basket" size={props.size}>
						<FontAwesomeIcon id={"icon" + props.qid} icon="shopping-basket" color="#222" />
					</Button>
				}
			</Fragment >
			:
			<Fragment key="{qid +'bb'}"  >
				<Button title="Register or login to use the export basket" size={props.size} >
					<OverlayTrigger trigger="click" placement="right" overlay={popover} rootClose>
						<FontAwesomeIcon icon="shopping-basket" color="#222" />
					</OverlayTrigger>
				</Button>
			</Fragment >

	)
}


export default connect(mapStateToProps, mapDispatchToProps)(BasketButton);