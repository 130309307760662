import './search.scss';
import './customStyle.scss';

import { connect } from 'react-redux';
import React, { useContext, Fragment } from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Link } from 'react-router-dom';


import * as componentUtility from "./componentUtility";

import { getEntity, updateEntity, createEntity, isInMyBasket, toggleInMyBasket, addInMyBasket, removeInMyBasket, reset, resetBasket } from '../entities/basket/basket.reducer';

import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';


import SearchContext from 'app/modules/context/SearchContext';
import BasketContext from 'app/modules/context/BasketContext';
import AuthContext from 'app/modules/context/AuthContext';

/* eslint no-console: 0 */
const popover = (
	<Popover id="popover-basic" placement="auto">
		<Popover.Title as="h3">Account required</Popover.Title>
		<Popover.Content>To use the export basket function
		<Link to="/login"> login </Link> or <Link to="/account/register"> register a new account.</Link>
		</Popover.Content>
	</Popover>
);


const mapStateToProps = (storeState) => ({
	users: storeState.userManagement.users,
	basketEntity: storeState.basket.entity,
	loading: storeState.basket.loading,
	updating: storeState.basket.updating,
	updateSuccess: storeState.basket.updateSuccess
});


const mapDispatchToProps = {
	getEntity,
	updateEntity,
	createEntity,
	isInMyBasket,
	resetBasket,
	toggleInMyBasket,
	addInMyBasket,
	removeInMyBasket,
	reset
};



function AllInBasketButton(props) {



	const basketContext = useContext(BasketContext);
	const searchContext = useContext(SearchContext);
	const authContext = useContext(AuthContext);

	const add = () => {
		const ids = searchContext.hitIds;
		const promise = addInMyBasket(ids).payload;

		promise.then(v => {
			basketContext.update({
				itemsinbasket: v.headers.itemsinbasket,
				itemsidsinbasket: v.headers.itemsidsinbasket
			});
		});
	};


	const remove = () => {
		const ids = searchContext.hitIds;
		const promise = removeInMyBasket(ids).payload;

		promise.then(v => {
			basketContext.update(
				{
					itemsinbasket: v.headers.itemsinbasket,
					itemsidsinbasket: v.headers.itemsidsinbasket
				});
		});
	};


	return (
		authContext.isAuthenticated==="true" ?
			<Fragment >
				{componentUtility.partOfHits(basketContext.itemsidsinbasket, searchContext.hitIds) ?
					<Button size="sm" onClick={() => remove()} title="remove from basket"  >
						<FontAwesomeIcon icon="shopping-basket" color="#17a2b8" />
					</Button>
					:
					<Button size="sm" onClick={() => add()} title="add to basket"  >
						<FontAwesomeIcon icon="shopping-basket" color="#222" />
					</Button>
				}
			</Fragment >
			:
			<Button size="sm" title="Register or login to use the export basket" >
				<OverlayTrigger trigger="click" placement="right" overlay={popover} rootClose>
					<FontAwesomeIcon icon="shopping-basket" color="#222" />
				</OverlayTrigger>
			</Button>

	)
}

export default connect(mapStateToProps, mapDispatchToProps)(AllInBasketButton);

