import './search.scss';
import './customStyle.scss';
import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Button, UncontrolledCollapse } from 'reactstrap';
import * as componentUtility from "./componentUtility";
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import ReadMoreReact from "read-more-react";
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


/* eslint no-console: 0 complexity: 0*/


import appConfig from 'app/config/constants';
const host = appConfig.ELASTICSEARCH_URL;
export class EQBStudyItem extends Component {

	constructor(props) {
		super(props)
		this.state = {
			myLang: "en"
		}
		this.langToggle = React.createRef();
	}

	setMyLang(event) {
		console.info(event);
		this.setState({ myLang: event })
	}


	langSelector(data) {
		const ds = [];
		data = data.filter((item, index) => data.indexOf(item) === index);
		for (let i = 0; i < data.length; i++) {
			ds.push(<Dropdown.Item onSelect={e => this.setMyLang(data[i])} size="sm" key={data[i]}>{data[i]}</Dropdown.Item>);
		}
		return <DropdownButton className="langsel" id="dropdown-display-lang" title={"Display language: " + this.state.myLang}>
			{ds}
		</DropdownButton>;
	}



	render() {

		const { bemBlocks, result } = this.props
		const rawJson = host + "/_doc/" + result._id;
		console.info(this.state.myLang)
		let studyTitle = undefined;

		studyTitle = componentUtility.langHandler(result._source?.studyTitles?.translations, this.state.myLang);
		if (studyTitle === undefined || studyTitle.length === 0) {
			studyTitle = componentUtility.langHandler(result._source.title, "en");
		}

		let studyGeographicLocationName = undefined;
		studyGeographicLocationName = componentUtility.langHandler(result._source?.studyGeographicLocationName?.translations, this.state.myLang);
		if (studyGeographicLocationName !== undefined) {
			console.info("");
			studyGeographicLocationName = studyGeographicLocationName.map((gr, index) =>
				<span key={index} >{gr}{index < studyGeographicLocationName.length - 1 ? " | " : ""} </span>
			);

		}

		// add ... description, level, identifier



		// COUNTRY < spacialCoverage

		let studyTopicalCoverage = undefined;
		studyTopicalCoverage = componentUtility.langHandler(result._source.studyTopicalCoverage?.translations, this.state.myLang);
		let languages = "";
		if (result._source.languagesCountries !== undefined && result._source.instrumentLanguages !== undefined) {
			languages = this.langSelector(Object.keys(result._source.instrumentLanguages?.translations));
		}
		let studyDescription = undefined;
		studyDescription = componentUtility.langHandler(result._source.studyDescriptions?.translations, this.state.myLang);
		if (studyDescription[0] === undefined) {
			studyDescription = ["n/a"];
		}
		if (studyDescription[0] == null) {
			studyDescription = ["n/a"];
		}

		let questionItemText = undefined;
		if (result._source.questionItemTexts !== undefined) {
			questionItemText = componentUtility.langHandler(result._source.questionItemTexts?.translations, this.state.myLang);
			if (questionItemText === undefined) {
				questionItemText = componentUtility.langHandler(result._source.questionItemTexts?.translations, "en");
			}
		}

		let questionGridText = undefined;
		if (result._source.questionGridTexts !== undefined) {
			questionItemText = componentUtility.langHandler(result._source.questionGridTexts?.translations, this.state.myLang);
			if (questionGridText === undefined) {
				questionGridText = componentUtility.langHandler(result._source.questionGridTexts?.translations, "en");
			}
		}

		let studyGroupsRefs = undefined;
		if (result._source.studyGroupsRefs !== undefined) {
			studyGroupsRefs = result._source.studyGroupsRefs.map((gr, index) =>
				<a key={index} href={gr.groupUri}>{gr.groupId}</a>
			);
		}
		let instrumentLanguages = undefined;
		if (result._source.instrumentLanguages !== undefined) {
			const keys = Object.keys(result._source.instrumentLanguages.translations);
			instrumentLanguages = keys.map((gr, index) =>
				<span key={index}> {gr}{index < keys.length - 1 ? " | " : ""} </span>
			);
		}

		const popover = (
			<Popover id="popover-basic" placement="auto">
				<Popover.Title as="h3">License</Popover.Title>
				<Popover.Content>
					{studyDescription}
				</Popover.Content>
			</Popover>
		);
		if (studyTitle === undefined) { studyTitle = ["no title"] }
		let cdcQueryTerm = result._source.studyDOI;
		if (cdcQueryTerm !== undefined) {
			cdcQueryTerm = cdcQueryTerm?.replace("doi:", "");
		}


		return (<Fragment>
			<Container>
				<Row>
					<Col md="3">
						<a href="/">
						</a>
					</Col>
					<Col md="5">
						<h2>Study details</h2>
					</Col>
					<Col md="2" className="text-right">{languages}</Col>
					<Col md="2" className="text-right">
						<a className=" langsel btn btn-primary btn-small " href={`/search?sort=questionItemID.keyword_asc&size=500&studyIDs[0]=${result._source.studyId}`}>
							Questions
						</a>
					</Col>
				</Row>

			</Container>
			<Container className="alternating">
				<Row>
					<Col md="3" className="caption">Title</Col>
					<Col md="9">
						<OverlayTrigger trigger="click" placement="right" overlay={popover} rootClose>
							<span>{studyTitle ? studyTitle : "n/a"}</span>
						</OverlayTrigger>
					</Col>
				</Row>
				<Row>
					{studyTitle !== "" && result._source.studyPublicationYear !== "" && result._source.studyNumber !== "" && result._source.studyDOI !== "" ? <Fragment>
						<Col md="3" className="caption">Citation:</Col>
						<Col md="9">{studyTitle ? studyTitle : "n/a"}, {result._source.studyPublicationYear},  {result._source.studyNumber},  {result._source.studyVersionNumber ? "Version " + result._source.studyVersionNumber : ""}, <a href={`http://doi.org/${result._source.studyDOI}`}>{result._source.studyDOI}</a></Col>
					</Fragment> : ""}
				</Row>
				<Row>
					<Col md="3" className="caption">Study PID/number:</Col>
					<Col md="9">{result._source.studyId ? result._source.studyId : "n/a"}</Col>
				</Row>
				<Row>
					<Col md="3" className="caption">Further information on study and data:</Col>
					<Col md="9">{result._source.spurl ? <a href={result._source.spurl} target="sp">{result._source.spurl}</a> : "n/a"}
					</Col>


				</Row>
				<Row>
					<Col md="3" className="caption">Versionnumber/-date:</Col>
					<Col md="9">{result._source.studyVersionNumber ? result._source.studyVersionNumber : "n/a"}/{result._source.studyVersionDate ? result._source.studyVersionDate : "n/a"}</Col>
				</Row>
				<Row>
					<Col md="3" className="caption">Part of study group/series:</Col>
					<Col md="9">{studyGroupsRefs ? studyGroupsRefs : "n/a"}</Col>

				</Row>
				<Row>
					<Col md="3" className="caption">Publisher:	</Col>
					<Col md="9">{result._source.studyPublisher ? result._source.studyPublisher : "n/a"}</Col>

				</Row>
				<Row>
					<Col md="3" className="caption">Publication date:</Col>
					<Col md="9">{result._source.studyVersionDate ? result._source.studyVersionDate : "n/a"}</Col>

				</Row>
				<Row>
					<Col md="3" className="caption">Interview Language:</Col>
					<Col md="9">{instrumentLanguages ? instrumentLanguages : "n/a"}</Col>

				</Row>
				<Row>
					<Col md="3" className="caption">Country:</Col>
					<Col md="9"> {studyGeographicLocationName ? studyGeographicLocationName : "n/a"} </Col>

				</Row>
				<Row>
					<Col md="3" className="caption">Topics:</Col>
					<Col md="9"> {studyTopicalCoverage ? studyTopicalCoverage.map((gr, index) =>
						<span key={index}> {gr}{index < studyTopicalCoverage.length - 1 ? " | " : ""} </span>
					) : "n/a"} </Col>

				</Row>
				<Row>
					<Col md="3" className="caption">Abstract:</Col>
					<Col md="9">
						{typeof studyDescription[0] === "string" ? studyDescription.map((note,i) =>
							<ReadMoreReact key={"knk"+i} min={20} readMoreText=" ...(read more)" max={140} text={note} />) : studyDescription}
					</Col>

				</Row>
				<Button size="sm" color="primary" id="toggler">
					Methodology
				</Button>
				<Row>
					<Col>
						<UncontrolledCollapse toggler="#toggler" className="offset-0">
							<Row>
								<Col md="3" className="caption">Data collection period:</Col>
								<Col md="9">Start: {result._source.startEndYear ? result._source.startEndYear.startDate : "n/a"}  End: {result._source.startEndYear ? result._source.startEndYear.endDate : "n/a"}</Col>
							</Row>
							<Row>
								<Col md="3" className="caption">Time dimension: <a data-tip='timeMethodTypeTip' data-for='timeMethodTypeTip'>
									&nbsp;<FontAwesomeIcon icon="question-circle" />
								</a>
									<ReactTooltip className="clickTip" data-place={top} delayHide={250} data-html={true} id='timeMethodTypeTip'>
										<span>Describes the time dimension of the data collection. <br />
								See <a href="https://ddialliance.org/Specification/DDI-CV/TimeMethod_1.0.0.html">here </a> for details.</span>
									</ReactTooltip></Col>
								<Col md="9">{result._source.timeMethodType ? result._source.timeMethodType : "n/a"}<br />
									{result._source.timeMethod ? result._source.timeMethod : "n/a"}</Col>
							</Row>
							<Row>
								<Col md="3" className="caption">Sampling procedure:
							<a data-tip='samplingProcedureTypeTip' data-for='samplingProcedureTypeTip'>
										&nbsp;<FontAwesomeIcon icon="question-circle" />
									</a>
									<ReactTooltip className="clickTip" data-place={top} delayHide={250} data-html={true} id='samplingProcedureTypeTip'>
										<span>A typology of sampling methods. <br />
								See <a href="https://ddialliance.org/Specification/DDI-CV/SamplingProcedure_1.1.html">here </a> for details.</span>
									</ReactTooltip>
								</Col>
								<Col md="9"> {result._source.samplingProcedureType ? result._source.samplingProcedureType : "n/a"} <br />
									{result._source.samplingProcedure ? result._source.samplingProcedure : "n/a"}
								</Col>
							</Row>
							<Row>
								<Col md="3" className="caption">Data collection mode:
							<a data-tip='timeMethodTypeTip' data-for='timeMethodTypeTip'>
										&nbsp;<FontAwesomeIcon icon="question-circle" />
									</a>
									<ReactTooltip className="clickTip" data-place={top} delayHide={250} data-html={true} id='timeMethodTypeTip'>
										<span>Describes the time dimension of the data collection. <br />
								See <a href="https://ddialliance.org/Specification/DDI-CV/TimeMethod_1.0.0.html">here </a> for details.</span>
									</ReactTooltip>
								</Col>
								<Col md="9">
									{result._source.modeOfCollectionType ? result._source.modeOfCollectionType : "n/a"}<br />

									{result._source.modeOfCollection ? result._source.modeOfCollection : "n/a"}
								</Col>
							</Row>
						</UncontrolledCollapse>
					</Col>
				</Row>



			</Container>
		</Fragment>)
	}
}
export default EQBStudyItem;