import './search.scss';
import './customStyle.scss';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';

import React, { Component, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import DefaultQuestionItem from "./question-item";

/* eslint no-console: 0 */
import { MatchQuery, SearchkitManager, SearchkitProvider, SearchBox, Hits, LayoutResults, Layout, LayoutBody } from "searchkit";


import appConfig from 'app/config/constants';
const host = appConfig.ELASTICSEARCH_URL;
let searchkitManager = new SearchkitManager(host);




export class Question extends Component {
	render() {
		console.info("");
		const questionEsId = this.props.location.pathname.substr(this.props.location.pathname.lastIndexOf('/') + 1);
		console.log("questionEsId: " + questionEsId);
		searchkitManager = new SearchkitManager(host);
		searchkitManager.addDefaultQuery((query) => {
			return query.addQuery(MatchQuery("_id", questionEsId))
		});

		return (
			<SearchkitProvider searchkit={searchkitManager}>
				<Layout size="s" className="search" id="search">
					<Helmet>
						<title>Question detail</title>
					</Helmet>	<Container>
						<LayoutBody className="row">
							<LayoutResults>
								<SearchBox mod="searchBoxQuestionDetail" searchOnChange={true} />
								<Hits hitsPerPage={1} className="is-12" itemComponent={<DefaultQuestionItem key={questionEsId} qid={questionEsId} />} />
							</LayoutResults>
						</LayoutBody>	</Container>
				</Layout>
			</SearchkitProvider>
		);
	}
}
export default Question;