import './search.scss';
import './customStyle.scss';

import { connect } from 'react-redux';
import React, { Fragment, useContext } from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import { getEntity, updateEntity, createEntity, isInMyComparison, toggleInMyComparison, reset } from '../entities/comparison/comparison.reducer';
import { Link } from 'react-router-dom';

import ComparisonContext from 'app/modules/context/ComparisonContext';

import AuthContext from 'app/modules/context/AuthContext';

/* eslint no-console: 0 */


const popover = (
	<Popover id="popover-basic" placement="auto">
		<Popover.Title as="h3">Account required</Popover.Title>
		<Popover.Content>To use the export basket function
		<Link to="/login"> login </Link> or <Link to="/account/register"> register a new account.</Link>
		</Popover.Content>
	</Popover>
);



const mapStateToProps = (storeState) => ({
	comparisonEntity: storeState.comparison.entity,
	updateSuccess: storeState.comparison.updateSuccess
});


const mapDispatchToProps = {
	getEntity,
	updateEntity,
	createEntity,
	isInMyComparison,
	toggleInMyComparison,
	reset
};


function ComparisonButton(props) {


	const comparisonContext = useContext(ComparisonContext);
	const authContext = useContext(AuthContext);

	const toggle = (qid) => {
		const promise = toggleInMyComparison(qid).payload;

		promise.then(v => {
			comparisonContext.update({ itemsidsincomparison: v.headers.itemsidsincomparison })
			comparisonContext.update({ itemsincomparison: v.headers.itemsincomparison })
		});
	};

	return (
		<Fragment key="{qid +'cb'}">
				{comparisonContext.itemsidsincomparison !== undefined && comparisonContext.itemsidsincomparison.includes(props.qid) === true ?
					<Button onClick={() => toggle(props.qid)} title=" remove from comparison" size={props.size}>
						<FontAwesomeIcon icon="list" color="#17a2b8" />
					</Button>
					:
					< Button onClick={() => toggle(props.qid)} title=" add to comparison" size={props.size}>
						<FontAwesomeIcon icon="list" color="#222" />
					</Button>
				}
			</Fragment >
	)
}
export default connect(mapStateToProps, mapDispatchToProps)(ComparisonButton);