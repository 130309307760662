import React, { Fragment, useContext } from 'react';


function InputLabel(props) {


	return (<Fragment>{props.txt}{props.lng !== undefined ? "  [" + props.lng + "]" : ""}</Fragment>);

}


export default InputLabel;