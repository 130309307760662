import React, { useState, useEffect, useContext, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Col, Row, Table, Button, Container, CardGroup, Card } from 'reactstrap';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import {
	Translate,
	translate,
	ICrudSearchAction,
	ICrudGetAllAction,
	getSortState,
	IPaginationBaseState,
	JhiPagination,
	JhiItemCount
} from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import arrayMove from "array-move";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import { IRootState } from 'app/shared/reducers';
import { getSearchEntities, getEntities, getEntity, isInMyComparison } from './comparison.reducer';
import QuestionVertical from 'app/search/question-vertical-component';
import { IComparison } from 'app/shared/model/comparison.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
/* eslint no-console: 0 */
import { getEntities as getPreferredContentLanguage } from 'app/entities/user-preferences/user-preferences.reducer';
import AuthContext from 'app/modules/context/AuthContext';

import ComparisonButton from 'app/search/comparison-button';

/* DRAG AND DROP SORTING FUNCTIONS BEING DEBUGGED

const getContainer = () => {
	return document.getElementById("app-view-container");
  };

const SortableQuestion = SortableElement((props) => {
return (
	<QuestionVertical qid={props.qid} cmpid={props.cmpid} nr={props.nr} />
)
});

const SortableQuestionList = SortableContainer(({ items }) => {
	return (
		<CardGroup>
				{items.map((comparison, i) => (
				  <SortableQuestion key={`item-${i}`} index={i} qid={comparison.qRef} cmpid={comparison.id} nr={i} />
				))}
			  </CardGroup>
	  );
	  
	});
*/



export interface IComparisonProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> { }

export const ComparisonMatrix = (props: IComparisonProps) => {
	const [search, setSearch] = useState('');
	const [paginationState, setPaginationState] = useState(getSortState(props.location, 1000));
	const [displayLang, setDisplayLang] = useState("en");
	const authContext: any = useContext(AuthContext);

	const { comparisonList, loading } = props;

	/* DRAG AND DROP SORTING FUNCTIONS BEING DEBUGGED

	const [items, setItems] = useState(comparisonList);


	const onSortEnd = ({ oldIndex, newIndex }) => {
	  setItems(arrayMove(items, oldIndex, newIndex));
	};
	
*/


	function updateBadge() {
		const el = document.getElementById("comparisonBadge");

		const p: any = Promise.resolve(props.isInMyComparison(0));
		p.then(v => {
			if (v.action.payload.headers.itemsincomparison === "0") {
				el.innerHTML = "";
			} else {
				el.innerHTML = v.action.payload.headers.itemsincomparison;
			}
		});
	}

	const getAllEntities = () => {
		if (search) {
			props.getSearchEntities(
				search,
				paginationState.activePage - 1,
				paginationState.itemsPerPage,
				`${paginationState.sort},${paginationState.order}`
			);
		} else {
			props.getEntities(paginationState.activePage - 1, paginationState.itemsPerPage, `${paginationState.sort},${paginationState.order}`);
		}
	};






	useEffect(() => {
		getAllEntities()
		updateBadge();
		function getToken() {
			console.info(authContext.isAuthenticated === "true")
			if (authContext.isAuthenticated) {
				const token: any = getPreferredContentLanguage();
				const r = Promise.resolve(token);
				r.then(v => {
					v.payload.then(x => {

						console.info(x.data[0].preferredContentLanguage);
						setDisplayLang(x.data[0].preferredContentLanguage);
					})
				})
			} else {
				setDisplayLang("en");
			}
		}
		getToken();
	}, [displayLang]);






	return (
		<Fragment>
			<Container>
				<Row xs="2">
					<Col sm="3">
					</Col>
					<Col sm="6">
						<h2 id="comparison-heading">Comparison</h2>
					</Col>

					<Col sm="3" className="text-right">
						{comparisonList && comparisonList.length > 0 ? <Button
							tag={Link}
							to={`/comparison/matrix/deleteall?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
							color="danger"
							size="sm"
						><FontAwesomeIcon icon="trash" />{' '}
							Remove all
						</Button> : ""}
					</Col>
				</Row>
			</Container>
			{comparisonList && comparisonList.length > 0 ? (

				/* DRAG AND DROP SORTING CALL - BEING DEBUGGED
				<SortableQuestionList items={comparisonList} onSortEnd={onSortEnd} axis={"xy"} /> 
				*/

				<CardGroup >
					{comparisonList.map((comparison, i) => (
						<QuestionVertical key={`col-${i}`} qid={comparison.qRef} cmpid={comparison.id} preferredDisplayLanguage={displayLang} nr={i} />
					))}
				</CardGroup>

			)
				: (!loading && (
					<Fragment>
						<Col sm="3">
						</Col>
						<Col sm="6" className="alert alert-warning">
							<Translate contentKey="ddiSearchApp.comparison.home.notFound">No Comparisons found</Translate>
						</Col>
						<Col sm="3">
						</Col>
					</Fragment>

				))}

		</Fragment >
	);
};

const mapStateToProps = ({ comparison, userPreferences }: any) => ({
	comparisonList: comparison.entities,
	loading: comparison.loading,
	totalItems: comparison.totalItems,
	displayLang: userPreferences.preferredContentLanguage
});



const mapDispatchToProps = {
	getSearchEntities,
	getEntities,
	getEntity,
	isInMyComparison,
	getPreferredContentLanguage
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ComparisonMatrix);
