import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, ICrudGetAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './comparison.reducer';
import { IComparison } from 'app/shared/model/comparison.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IComparisonDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> { }

export const ComparisonDetail = (props: IComparisonDetailProps) => {
	useEffect(() => {
		props.getEntity(props.match.params.id);
	}, []);

	const { comparisonEntity } = props;
	return (
		<Row>
			<Col md="8">
				<h2>
					<Translate contentKey="ddiSearchApp.comparison.detail.title">Comparison</Translate> [<b>{comparisonEntity.id}</b>]
        </h2>
				<dl className="jh-entity-details">
					<dt>
						<span id="qRef">
							<Translate contentKey="ddiSearchApp.comparison.qRef">Q Ref</Translate>
						</span>
					</dt>
					<dd>{comparisonEntity.qRef}</dd>
					<dt>
						<Translate contentKey="ddiSearchApp.comparison.user">User</Translate>
					</dt>
					<dd>{comparisonEntity.user ? comparisonEntity.user.login : ''}</dd>
				</dl>
				<Button tag={Link} to="/comparison" replace color="info">
					<FontAwesomeIcon icon="arrow-left" />{' '}
					<span className="d-none d-md-inline">
						<Translate contentKey="entity.action.back">Back</Translate>
					</span>
				</Button>
				<Button
					tag={Link}
					to={`/comparison/${comparisonEntity.id}/delete`}
					color="danger">
					<FontAwesomeIcon icon="trash" />{' '}
					<span className="d-none d-md-inline">
						<Translate contentKey="entity.action.delete">Delete</Translate>
					</span>
				</Button>
			</Col>
		</Row>
	);
};

const mapStateToProps = ({ comparison }: IRootState) => ({
	comparisonEntity: comparison.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ComparisonDetail);
