import React, { Component } from 'react'
import BasketContext from './BasketContext'

/* eslint no-console: 0 */
class BasketProvider extends Component {
	// We'll use this component's state to hold our context. We'll
	// also add an `update` function to the state that will allow
	// us to update the state from anywhere in our application by
	// calling this.context.update().
	constructor(props) {
		super(props)
		this.updateState = this.updateState.bind(this)
		this.state = {
			hitIds:"",
			update: this.updateState
		}
	}

	// Call `this.context.update({ key: value })` from a consumer
	// to update this state.
	updateState(values) {
		this.setState(values)
	}

	// Wrap the children in BasketContext.Provider and pass in the
	// state as a value.
	render() {
		return (
			<BasketContext.Provider value={this.state}>
				{this.props.children}
			</BasketContext.Provider>
		)
	}
}

export default BasketProvider