import React, { useState, useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import { IRootState } from 'app/shared/reducers';
import { login } from 'app/shared/reducers/authentication';
import LoginModal from './login-modal';
/* eslint no-console: 0   */
import AuthContext from 'app/modules/context/AuthContext';
import BasketContext from 'app/modules/context/BasketContext';
import ComparisonContext from 'app/modules/context/ComparisonContext';
import { isInMyBasket } from 'app/entities/basket/basket.reducer';
import { isInMyComparison } from 'app/entities/comparison/comparison.reducer';

export interface ILoginProps extends StateProps, DispatchProps, RouteComponentProps<{}> { }

export const Login = (props: ILoginProps) => {
	const [showModal, setShowModal] = useState(props.showModal);

	const authContext: any = useContext(AuthContext);
	const basketContext: any = useContext(BasketContext);
	const comparisonContext: any = useContext(ComparisonContext);
	useEffect(() => {
		setShowModal(true);
	}, []);

	const handleLogin = (username, password, rememberMe = false) => props.login(username, password, rememberMe);

	const handleClose = () => {
		setShowModal(false);
		props.history.push('/');
	};

	const { location, isAuthenticated } = props;
	if (isAuthenticated) {


		const promise: any = isInMyBasket(0)
		const ppromise = promise.payload;

		ppromise.then(v => {
			console.info(basketContext)
			console.info(authContext)
			basketContext.update({
				itemsinbasket: v.headers.itemsinbasket,
				itemsidsinbasket: v.headers.itemsidsinbasket
			});
			authContext.update({ isAdmin: v.headers.isadmin });
			authContext.update({ isAuthenticated: v.headers.isauthenticated });
		});

		const cromise: any = isInMyComparison(0)
		const cpromise = cromise.payload;

		cpromise.then(v => {
			console.info(comparisonContext)
			comparisonContext.update({
				itemsincomparison: v.headers.itemsincomparison,
				itemsidsincomparison: v.headers.itemsidsincomparison
			});
		});
		return <Redirect to="/search" />;
	}
	return <LoginModal showModal={showModal} handleLogin={handleLogin} handleClose={handleClose} loginError={props.loginError} />;
};

const mapStateToProps = ({ authentication }: IRootState) => ({
	isAuthenticated: authentication.isAuthenticated,
	isAdmin: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.ADMIN]),
	loginError: authentication.loginError,
	showModal: authentication.showModalLogin
});

const mapDispatchToProps = { login };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Login);
