import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, InputGroup, Col, Row, Table } from 'reactstrap';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import {
	Translate,
	translate,
	ICrudSearchAction,
	ICrudGetAllAction,
	getSortState,
	IPaginationBaseState,
	JhiPagination,
	JhiItemCount
} from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getSearchEntities, getEntities } from './comparison.reducer';
import { IComparison } from 'app/shared/model/comparison.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';

export interface IComparisonProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> { }

export const Comparison = (props: IComparisonProps) => {
	const [search, setSearch] = useState('');
	const [paginationState, setPaginationState] = useState(getSortState(props.location, ITEMS_PER_PAGE));

	const getAllEntities = () => {
		if (search) {
			props.getSearchEntities(
				search,
				paginationState.activePage - 1,
				paginationState.itemsPerPage,
				`${paginationState.sort},${paginationState.order}`
			);
		} else {
			props.getEntities(paginationState.activePage - 1, paginationState.itemsPerPage, `${paginationState.sort},${paginationState.order}`);
		}
	};

	const startSearching = () => {
		if (search) {
			setPaginationState({
				...paginationState,
				activePage: 1
			});
			props.getSearchEntities(
				search,
				paginationState.activePage - 1,
				paginationState.itemsPerPage,
				`${paginationState.sort},${paginationState.order}`
			);
		}
	};

	const clear = () => {
		setSearch('');
		setPaginationState({
			...paginationState,
			activePage: 1
		});
		props.getEntities();
	};

	const handleSearch = event => setSearch(event.target.value);

	const sortEntities = () => {
		getAllEntities();
		props.history.push(
			`${props.location.pathname}?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`
		);
	};

	useEffect(() => {
		sortEntities();
	}, [paginationState.activePage, paginationState.order, paginationState.sort, search]);

	const sort = p => () => {
		setPaginationState({
			...paginationState,
			order: paginationState.order === 'asc' ? 'desc' : 'asc',
			sort: p
		});
	};

	const handlePagination = currentPage =>
		setPaginationState({
			...paginationState,
			activePage: currentPage
		});

	const { comparisonList, match, loading, totalItems } = props;
	return (
		<div>
			<h2 id="comparison-heading">
				<Translate contentKey="ddiSearchApp.comparison.home.title">Comparisons</Translate>
				<Link to={`${match.url}/new`} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
					<FontAwesomeIcon icon="plus" />
          &nbsp;
          <Translate contentKey="ddiSearchApp.comparison.home.createLabel">Create new Comparison</Translate>
				</Link>
			</h2>
			<Row>
				<Col sm="12">
					<AvForm onSubmit={startSearching}>
						<AvGroup>
							<InputGroup>
								<AvInput
									type="text"
									name="search"
									value={search}
									onChange={handleSearch}
									placeholder={translate('ddiSearchApp.comparison.home.search')}
								/>
								<Button className="input-group-addon">
									<FontAwesomeIcon icon="search" />
								</Button>
								<Button type="reset" className="input-group-addon" onClick={clear}>
									<FontAwesomeIcon icon="trash" />
								</Button>
							</InputGroup>
						</AvGroup>
					</AvForm>
				</Col>
			</Row>
			<div className="table-responsive">
				{comparisonList && comparisonList.length > 0 ? (
					<Table responsive>
						<thead>
							<tr>
								<th className="hand" onClick={sort('id')}>
									<Translate contentKey="global.field.id">ID</Translate> <FontAwesomeIcon icon="sort" />
								</th>
								<th className="hand" onClick={sort('qRef')}>
									<Translate contentKey="ddiSearchApp.comparison.qRef">Q Ref</Translate> <FontAwesomeIcon icon="sort" />
								</th>
								<th>
									<Translate contentKey="ddiSearchApp.comparison.user">User</Translate> <FontAwesomeIcon icon="sort" />
								</th>
								<th />
							</tr>
						</thead>
						<tbody>
							{comparisonList.map((comparison, i) => (
								<tr key={`entity-${i}`}>
									<td>
										<Button tag={Link} to={`${match.url}/${comparison.id}`} color="link" size="sm">
											{comparison.id}
										</Button>
									</td>
									<td>{comparison.qRef}</td>
									<td>{comparison.user ? comparison.user.login : ''}</td>
									<td className="text-right">
										<div className="btn-group flex-btn-group-container">
											<Button tag={Link} to={`${match.url}/${comparison.id}`} color="info" size="sm">
												<FontAwesomeIcon icon="eye" />{' '}
												<span className="d-none d-md-inline">
													<Translate contentKey="entity.action.view">View</Translate>
												</span>
											</Button>
											<Button
												tag={Link}
												to={`${match.url}/${comparison.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
												color="primary"
												size="sm"
											>
												<FontAwesomeIcon icon="pencil-alt" />{' '}
												<span className="d-none d-md-inline">
													<Translate contentKey="entity.action.edit">Edit</Translate>
												</span>
											</Button>
										</div>
									</td>
								</tr>
							))}
						</tbody>
					</Table>
				) : (
						!loading && (
							<div className="alert alert-warning">
								<Translate contentKey="ddiSearchApp.comparison.home.notFound">No Comparisons found</Translate>
							</div>
						)
					)}
			</div>
			<div className={comparisonList && comparisonList.length > 0 ? '' : 'd-none'}>
				<Row className="justify-content-center">
					<JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
				</Row>
				<Row className="justify-content-center">
					<JhiPagination
						activePage={paginationState.activePage}
						onSelect={handlePagination}
						maxButtons={5}
						itemsPerPage={paginationState.itemsPerPage}
						totalItems={props.totalItems}
					/>
				</Row>
			</div>
		</div>
	);
};

const mapStateToProps = ({ comparison }: IRootState) => ({
	comparisonList: comparison.entities,
	loading: comparison.loading,
	totalItems: comparison.totalItems
});

const mapDispatchToProps = {
	getSearchEntities,
	getEntities
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Comparison);
