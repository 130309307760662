import React, { useContext, useEffect, useState } from 'react';
import QuestionHitItem from "./search-item";
import AllInBasketButton from "./allInBasket-button";
import AllInComparisonButton from "./allInComparison-button";
/* eslint no-console: 0 */
import AuthContext from 'app/modules/context/AuthContext';

import SearchContext from 'app/modules/context/SearchContext';
import { getEntities as getPreferredContentLanguage } from 'app/entities/user-preferences/user-preferences.reducer';
import { connect } from 'react-redux';


function ResultListComponent(props) {
	const [displayLang, setDisplayLang] = useState(undefined);

	const authContext = useContext(AuthContext);


	const dlang = () => {
		if (authContext.isAuthenticated === "true") {
			const promise = getPreferredContentLanguage().payload;
			promise.then(v => {
				if (v.status === 200) {
					setDisplayLang(v.data[0].preferredContentLanguage)
				}
			});
		}
	};

	useEffect(() => {
		dlang();

	}, [authContext]);


	const searchContext = useContext(SearchContext);
	console.info(displayLang);

	useEffect(() => {
		dlang();
	}, [searchContext]);
	return (
		<div>
			<div className="bbgrey">
				<AllInBasketButton hits={searchContext.hitIds} />
				<span>&nbsp;Add all to basket&nbsp;</span>
				<AllInComparisonButton hits={searchContext.hitIds} />
				<span>&nbsp;Add all to comparison</span>
			</div>
			{props.hits.map((hit, index) =>
				<QuestionHitItem key={index} result={hit} preferredDisplayLanguage={displayLang} />
			)}
		</div>
	);
}
const mapDispatchToProps = {
	getPreferredContentLanguage
};
connect(mapDispatchToProps)
export default ResultListComponent;