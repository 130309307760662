import React, { Component } from 'react'
import SearchContext from './SearchContext'

/* eslint no-console: 0 */
class SearchProvider extends Component {
	constructor(props) {
		super(props)
		this.updateState = this.updateState.bind(this)
		this.state = {
			hitIds:"",
			searchFieldPaths:[],
			update: this.updateState
		}
	}

	updateState(values) {
		this.setState(values)
	}

	render() {
		return (
			<SearchContext.Provider value={this.state}>
				{this.props.children}
			</SearchContext.Provider>
		)
	}
}

export default SearchProvider