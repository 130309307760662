import axios from 'axios';
import { ICrudSearchAction, ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IComparison, defaultValue } from 'app/shared/model/comparison.model';
/* eslint no-console: 0  no-dupe-keys: 0 */

export const ACTION_TYPES = {
	SEARCH_COMPARISONS: 'comparison/SEARCH_COMPARISONS',
	FETCH_COMPARISON_LIST: 'comparison/FETCH_COMPARISON_LIST',
	FETCH_COMPARISON: 'comparison/FETCH_COMPARISON',
	CREATE_COMPARISON: 'comparison/CREATE_COMPARISON',
	UPDATE_COMPARISON: 'comparison/UPDATE_COMPARISON',
	ADD_COMPARISON: 'basket/ADD_COMPARISON',
	REMOVE_COMPARISON: 'basket/REMOVE_COMPARISON',
	TOGGLE_COMPARISON: 'basket/TOGGLE_COMPARISON',
	DELETE_COMPARISON: 'comparison/DELETE_COMPARISON',
	RESET_COMPARISON: 'comparison/RESET_COMPARISON',
	RESET: 'comparison/RESET'
};

const initialState = {
	loading: false,
	errorMessage: null,
	entities: [] as ReadonlyArray<IComparison>,
	entity: defaultValue,
	updating: false,
	totalItems: 0,
	updateSuccess: false
};

export type ComparisonState = Readonly<typeof initialState>;

// Reducer

export default (state: ComparisonState = initialState, action): ComparisonState => {
	switch (action.type) {
		case REQUEST(ACTION_TYPES.SEARCH_COMPARISONS):
		case REQUEST(ACTION_TYPES.FETCH_COMPARISON_LIST):
		case REQUEST(ACTION_TYPES.FETCH_COMPARISON):
			return {
				...state,
				errorMessage: null,
				updateSuccess: false,
				loading: true
			};
		case REQUEST(ACTION_TYPES.CREATE_COMPARISON):
		case REQUEST(ACTION_TYPES.UPDATE_COMPARISON):
		case REQUEST(ACTION_TYPES.TOGGLE_COMPARISON):
		case REQUEST(ACTION_TYPES.ADD_COMPARISON):
		case REQUEST(ACTION_TYPES.REMOVE_COMPARISON):
		case REQUEST(ACTION_TYPES.DELETE_COMPARISON):
		case REQUEST(ACTION_TYPES.RESET_COMPARISON):
			return {
				...state,
				errorMessage: null,
				updateSuccess: false,
				updating: true
			};
		case FAILURE(ACTION_TYPES.SEARCH_COMPARISONS):
		case FAILURE(ACTION_TYPES.FETCH_COMPARISON_LIST):
		case FAILURE(ACTION_TYPES.FETCH_COMPARISON):
		case FAILURE(ACTION_TYPES.CREATE_COMPARISON):
		case FAILURE(ACTION_TYPES.UPDATE_COMPARISON):
		case FAILURE(ACTION_TYPES.TOGGLE_COMPARISON):
		case FAILURE(ACTION_TYPES.ADD_COMPARISON):
		case FAILURE(ACTION_TYPES.REMOVE_COMPARISON):
		case FAILURE(ACTION_TYPES.DELETE_COMPARISON):
		case FAILURE(ACTION_TYPES.RESET_COMPARISON):
			return {
				...state,
				loading: false,
				updating: false,
				updateSuccess: false,
				errorMessage: action.payload
			};
		case SUCCESS(ACTION_TYPES.SEARCH_COMPARISONS):
		case SUCCESS(ACTION_TYPES.FETCH_COMPARISON_LIST):
			return {
				...state,
				loading: false,
				entities: action.payload.data,
				totalItems: parseInt(action.payload.headers['x-total-count'], 10)
			};
		case SUCCESS(ACTION_TYPES.FETCH_COMPARISON):
			return {
				...state,
				loading: false,
				entity: action.payload.data
			};
		case SUCCESS(ACTION_TYPES.CREATE_COMPARISON):
		case SUCCESS(ACTION_TYPES.UPDATE_COMPARISON):
		case SUCCESS(ACTION_TYPES.TOGGLE_COMPARISON):
		case SUCCESS(ACTION_TYPES.ADD_COMPARISON):
		case SUCCESS(ACTION_TYPES.REMOVE_COMPARISON):
			return {
				...state,
				updating: false,
				updateSuccess: true,
				entity: action.payload.data
			};
		case SUCCESS(ACTION_TYPES.DELETE_COMPARISON):
		case SUCCESS(ACTION_TYPES.RESET_COMPARISON):
			return {
				...state,
				updating: false,
				updateSuccess: true,
				entity: {}
			};
		case ACTION_TYPES.RESET:
			return {
				...initialState
			};
		default:
			return state;
	}
};

const myApiUrl = 'api/comparisons/my';
const myApiAddUrl = 'api/comparisons/my-add';
const myApiRemoveUrl = 'api/comparisons/my-remove';
const apiUrl = 'api/comparisons';
const apiSearchUrl = 'api/_search/comparisons';

// Actions

export const getSearchEntities: ICrudSearchAction<IComparison> = (query, page, size, sort) => ({
	type: ACTION_TYPES.SEARCH_COMPARISONS,
	payload: axios.get<IComparison>(`${apiSearchUrl}?query=${query}${sort ? `&page=${page}&size=${size}&sort=${sort}` : ''}`)
});

export const getEntities: ICrudGetAllAction<IComparison> = (page, size, sort) => {
	const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
	return {
		type: ACTION_TYPES.FETCH_COMPARISON_LIST,
		payload: axios.get<IComparison>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
	};
};

export const isInMyComparison: ICrudGetAction<IComparison> = id => {
	const requestUrl = `${myApiUrl}/${id}`;
	return {
		type: ACTION_TYPES.FETCH_COMPARISON,
		payload: axios.get<IComparison>(requestUrl)
	};
};

export const toggleInMyComparison: ICrudPutAction<IComparison> = id  => {
	const requestUrl = `${myApiUrl}/toggle/${id}`;
	return {
		type: ACTION_TYPES.TOGGLE_COMPARISON,
		payload: axios.get<IComparison>(requestUrl)
	};
};



export const addInMyComparison: ICrudPutAction<IComparison> = id => {
	const requestUrl = `${myApiAddUrl}/${id}`;
	return {
		type: ACTION_TYPES.ADD_COMPARISON,
		payload: axios.get<IComparison>(requestUrl)
	};
};


export const removeInMyComparison: ICrudPutAction<IComparison> = id => {
	const requestUrl = `${myApiRemoveUrl}/${id}`;
	return {
		type: ACTION_TYPES.REMOVE_COMPARISON,
		payload: axios.get<IComparison>(requestUrl)
	};
};


export const getEntity: ICrudGetAction<IComparison> = id => {
	const requestUrl = `${apiUrl}/${id}`;
	return {
		type: ACTION_TYPES.FETCH_COMPARISON,
		payload: axios.get<IComparison>(requestUrl)
	};
};

export const createEntity: ICrudPutAction<IComparison> = entity => async dispatch => {
	const result = await dispatch({
		type: ACTION_TYPES.CREATE_COMPARISON,
		payload: axios.post(apiUrl, cleanEntity(entity))
	});
	dispatch(getEntities());
	return result;
};

export const updateEntity: ICrudPutAction<IComparison> = entity => async dispatch => {
	const result = await dispatch({
		type: ACTION_TYPES.UPDATE_COMPARISON,
		payload: axios.put(apiUrl, cleanEntity(entity))
	});
	return result;
};

export const deleteEntity: ICrudDeleteAction<IComparison> = id => async dispatch => {
	const requestUrl = `${apiUrl}/${id}`;
	const result = await dispatch({
		type: ACTION_TYPES.DELETE_COMPARISON,
		payload: axios.delete(requestUrl)
	});
	return result;
};


export const resetComparison: ICrudGetAction<IComparison> = () => {
	const requestUrl = `${myApiUrl}/reset`;
	return {
		type: ACTION_TYPES.RESET_COMPARISON,
		payload: axios.get<IComparison>(`${requestUrl}`)
	};
};

export const reset = () => ({
	type: ACTION_TYPES.RESET
});
