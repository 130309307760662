import './search.scss';
import './customStyle.scss';

import { connect } from 'react-redux';
import React, { useContext, Fragment } from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Link } from 'react-router-dom';


import * as componentUtility from "./componentUtility";

import { getEntity, updateEntity, createEntity, isInMyComparison, toggleInMyComparison, addInMyComparison, removeInMyComparison, reset, resetComparison } from '../entities/comparison/comparison.reducer';

import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import SearchContext from 'app/modules/context/SearchContext';
import AuthContext from 'app/modules/context/AuthContext';
import ComparisonContext from 'app/modules/context/ComparisonContext';
/* eslint no-console: 0 */
const popover = (
	<Popover id="popover-basic" placement="auto">
		<Popover.Title as="h3">Account required</Popover.Title>
		<Popover.Content>To use the export comparison function
		<Link to="/login"> login </Link> or <Link to="/account/register"> register a new account.</Link>
		</Popover.Content>
	</Popover>
);


const mapStateToProps = (storeState) => ({
	users: storeState.userManagement.users,
	comparisonEntity: storeState.comparison.entity,
	loading: storeState.comparison.loading,
	updating: storeState.comparison.updating,
	updateSuccess: storeState.comparison.updateSuccess
});
const mapDispatchToProps = {
	getEntity,
	updateEntity,
	createEntity,
	isInMyComparison,
	resetComparison,
	toggleInMyComparison,
	addInMyComparison,
	removeInMyComparison,
	reset
};
function AllInComparisonButton(props) {



	const comparisonContext = useContext(ComparisonContext);
	const searchContext = useContext(SearchContext);
	const authContext = useContext(AuthContext);

	const add = () => {
		const ids = searchContext.hitIds;
		console.info("ADDING: " + ids);
		const promise = addInMyComparison(ids).payload;

		promise.then(v => {
			comparisonContext.update({
				itemsincomparison: v.headers.itemsincomparison,
				itemsidsincomparison: v.headers.itemsidsincomparison
			});
		});
	};


	const remove = () => {
		const ids = searchContext.hitIds;
		console.info("REMOVING: " + ids);
		const promise = removeInMyComparison(ids).payload;

		promise.then(v => {
			comparisonContext.update(
				{
					itemsincomparison: v.headers.itemsincomparison,
					itemsidsincomparison: v.headers.itemsidsincomparison
				});
		});
	};


	return (
		authContext.isAuthenticated==="true" ?
			<Fragment >
				{componentUtility.partOfHits(comparisonContext.itemsidsincomparison, searchContext.hitIds) ?
					<Button size="sm" onClick={() => remove()} title="remove from comparison"  >
						<FontAwesomeIcon icon="list" color="#17a2b8" />
					</Button>
					:
					<Button size="sm" onClick={() => add()} title="add to comparison"  >
						<FontAwesomeIcon icon="list" color="#222" />
					</Button>
				}
			</Fragment >
			:
			<Button size="sm" title="Register or login to use the export comparison" >
				<OverlayTrigger trigger="click" placement="right" overlay={popover} rootClose>
					<FontAwesomeIcon icon="list" color="#222" />
				</OverlayTrigger>
			</Button>

	)
}


export default connect(mapStateToProps, mapDispatchToProps)(AllInComparisonButton);

