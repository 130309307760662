import * as React from "react";

import * as ReactDOM from "react-dom";
import Button from 'react-bootstrap/Button';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import * as _ from 'lodash';
import iso6931 from "iso-639-1";
import parse from 'html-react-parser';
import ReadMoreReact from "read-more-react";

/* eslint no-console: 0  no-dupe-keys: 0 */

/* see curl -X GET "http://localhost:9000/api/languages" */
export const multilingualFields = ["questionItemStatements",
	"questionItemTexts",
	"questionGridTexts",
	"subQuestionTexts",
	"questionItemNumbers",
	"codelistLabels",
	"studyTitles",
	"questionItemInterviewerInstructions",
	"studyGeographicLocationName",
	"questionGridInterviewerInstructions",
	"questionType",
	"studyGeographicLocationLevelDescription",
	"questionItemNames",
	"questionGridStatements",
	"subQuestionNumbers",
	"instrumentNames",
	"studyDescriptions",
	"instrumentLanguages",
	"publisherNames",
	"studyTopicalCoverage",
	"variableTypes",
	"codelistNames",
	"studyGeographicLocationIdentifier",
	"variableNames",
	"variableDescriptions",
	"codelistDescriptions",
	"variableLabels",
	"questionGridNames",
	"studyGeographicLocationDescription",
	"answerCategories"];

export const langCountryCodes = ["bs-ba",
	"ro-md",
	"nl-be",
	"de",
	"ru-by",
	"el-cy",
	"hr-hr",
	"fr-fr",
	"az-az",
	"de-de",
	"cz-cz",
	"lt-lt",
	"hu-hu",
	"hu-sk",
	"ru-ge",
	"ro-ro",
	"se-se",
	"fr-lu",
	"pt-pt",
	"da-dk",
	"de-ch",
	"ru-ua",
	"de-lu",
	"de-at",
	"hu-ro",
	"tr-cy-tcc",
	"gr-gr",
	"et-ee",
	"el-gr",
	"it-ch",
	"hy-am",
	"sl-si",
	"en-lu",
	"en-de",
	"by-by",
	"mk-mk",
	"ru-ee",
	"ru-az",
	"es-es",
	"ru-ru",
	"en-gb-nir",
	"no-no",
	"is-is",
	"en-gb-gbn",
	"en-mt",
	"mt-mt",
	"cs-cz",
	"it-it",
	"fr-be",
	"bg-bg",
	"lv-lv",
	"uk-ua",
	"ka-ge",
	"sk-sk",
	"nl-nl",
	"sv-se",
	"fi",
	"fi-fi",
	"dk-dk",
	"lb-lu",
	"sq-al",
	"cnr-me",
	"pt-lu",
	"fr-ch",
	"pl-pl",
	"ru-md",
	"en-ie",
	"en",
	"tr-tr",
	"sr-rs",
	"ru-lv",
	"sq-mk"];


export const langCodes = [
	"all",
	"az",
	"bg",
	"bs",
	"by",
	"cnr",
	"cs",
	"cz",
	"da",
	"de",
	"dk",
	"el",
	"en",
	"es",
	"et",
	"fi",
	"fr",
	"gr",
	"hr",
	"hu",
	"hy",
	"is",
	"it",
	"ka",
	"lb",
	"lt",
	"lv",
	"mk",
	"mt",
	"nl",
	"no",
	"pl",
	"pt",
	"ro",
	"ru",
	"se",
	"sk",
	"sl",
	"sq",
	"sr",
	"sv",
	"tr",
	"uk",

];




export function partOfHits(basket, hits) {
	if (!basket) return false;
	if (!hits) return false;
	const basketArr = basket.split(",").map(s => s.trim());
	const hitsArr = hits.split(",").map(s => s.trim());
	const complete = hitsArr.every(hitItem => {

		const inHits = basketArr.indexOf(hitItem) > -1;
		return inHits;
	})
	return complete;

}




export function langName(twoLetters) {
	if (twoLetters === "all") { return "All languages" }
	if (iso6931.getName(twoLetters) === "") {
		return twoLetters;
	}

	return iso6931.getName(twoLetters);
}
export function txt2Html(data) {

	if (data === undefined || data.length === 0) {
		return undefined;
	} else {
		const htm = [];
		for (let i = 0; i < data.length; i++) {
			htm.push(<span key={'hl-' + i}> {parse(data[i] + '&nbsp;')}</span>);
		}
		return htm;
	}

}
export function languagesOfItemAndGridTexts(esResult) {
	const gridContainer = esResult._source?.questionGridTexts?.translations
	const itemContainer = esResult._source?.questionItemTexts?.translations
	const langCntryCodes = [];
	if (gridContainer) {
		const k = Object.keys(gridContainer);
		k.forEach(item => {
			langCntryCodes.push(item);
		});
	}
	if (itemContainer) {
		const k = Object.keys(itemContainer);
		k.forEach(item => {
			langCntryCodes.push(item);
		});
	}
	return langCntryCodes;
}
export function languagesOfStudyTitles(esResult) {
	const gridContainer = esResult._source?.studyTitles?.translations
	const langCntryCodes = [];
	if (gridContainer) {
		const k = Object.keys(gridContainer);
		k.forEach(item => {
			langCntryCodes.push(item);
		});
	}
	return langCntryCodes;
}
export function langHandler(container, preferredLang, keyPrefix, maxLen) {
	if (container === undefined) {
		return [];
	}
	if (keyPrefix === undefined) {
		keyPrefix = "k-";
	}
	const langCntryCodes = Object.keys(container);
	if (container === undefined) {
		return undefined;
	} if (container === null) {
		return [];
	}
	// try preferred lang 
	console.log(langCntryCodes);
	let langBag = container[preferredLang];

	console.log(langBag);
	// first fallback any with lang code part matching preferred path
	if (langBag === undefined) {

		const result = langCntryCodes.findIndex((lng) => { return lng.startsWith(preferredLang); }, preferredLang)
		if (result >= 0) {
			const l = langCntryCodes[result];
			langBag = container[l];
			if (maxLen !== undefined) {
				langBag = container[l.substring(0, maxLen)];
			}
		}
	}
	// second fallback to all available languages
	if (langBag === undefined || langBag === "") {
		langBag = [];
		for (let i = 0; i < langCntryCodes.length; i++) {
			if (container[langCntryCodes[i]] !== undefined) {
				if (typeof container[langCntryCodes[i]] === "object") {
					container[langCntryCodes[i]].map((item, index) => {
						if (maxLen !== undefined) {
							langBag.push(<span
								key={keyPrefix + "-" + i + '-' + langCntryCodes[i] + '-' + index}
								className="notPrefferedLang"
								title={"Preferred language " + preferredLang + " not available. Displaying " + langCntryCodes[i]}>
								{container[langCntryCodes[i]][index].substring(0, maxLen)}
							</span>);
						} else {
							langBag.push(<span
								key={keyPrefix + "-" + i + '-' + langCntryCodes[i] + '-' + index}
								className="notPrefferedLang"
								title={"Preferred language " + preferredLang + " not available. Displaying " + langCntryCodes[i]}>
								{container[langCntryCodes[i]][index]}
							</span>);
						}

					});
				}
				else {
					if (maxLen !== undefined) {
						langBag.push(container[langCntryCodes[i]]).substring(0, maxLen);
					} else {
						langBag.push(container[langCntryCodes[i]]);
					}
				}
			}
		}
	}
	if (langBag === undefined) {
		langBag = ["n/a"];
	}
	if (maxLen !== undefined) {
		const langBag2 = [];

		console.log(langBag);
		langBag.map(i => {
			langBag2.push(i.substring(0, maxLen));
		})
		return langBag2;
	}
	return langBag;
}




export function langHandlerTag(container, preferredLang, keyPrefix, tag) {
	if (container === undefined) {
		return [];
	}
	if (keyPrefix === undefined) {
		keyPrefix = "k-";
	}
	const langCntryCodes = Object.keys(container);
	if (container === undefined) {
		return undefined;
	} if (container === null) {
		return [];
	}
	// try preferred lang 
	console.log(langCntryCodes);
	let langBag = undefined;

	console.log(langBag);
	// second fallback to all available languages
	if (langBag === undefined || langBag === "") {
		langBag = [];
		for (let i = 0; i < langCntryCodes.length; i++) {
			if (container[langCntryCodes[i]] !== undefined) {
				if (typeof container[langCntryCodes[i]] === "object") {
					container[langCntryCodes[i]].map((item, index) => {
						console.info("XXXD" + item);
						let code = item;
						let cat = "";
						if (item.includes("|")) {
							const arr = item.split("|");
							code = arr[0];
							cat = arr[1];
						}
						if (tag === "li") {
							langBag.push(<li
								key={keyPrefix + "-" + i + '-' + langCntryCodes[i] + '-' + index}
								title={"Displaying " + langCntryCodes[i]}>
								{"[" + code + "] " + cat}
							</li>);
						} else {
							langBag.push(<li
								key={keyPrefix + "-" + i + '-' + langCntryCodes[i] + '-' + index}
								title={"Displaying " + langCntryCodes[i]}>
								{"[" + code + "] " + cat}
							</li>);
						}

					});
				}
				else {
					if (container[langCntryCodes[i]].includes("|")) {
						const arr = container[langCntryCodes[i]].split("|");
						const code = arr[0];
						const cat = arr[1];
						langBag.push(<li>{"[" + code + "] " + cat}</li>);
					} else {

						langBag.push(<li>container[langCntryCodes[i]]</li>);
					}
				}
			}
		}
	}
	if (langBag === undefined) {
		langBag = ["n/a"];
	}
	return langBag;
}



export function langHandlerSD(container, preferredLang, keyPrefix) {
	if (container === undefined) {
		return [];
	}
	if (keyPrefix === undefined) {
		keyPrefix = "ksd-";
	}
	const langCntryCodes = Object.keys(container);
	if (container === undefined) {
		return undefined;
	} if (container === null) {
		return [];
	}
	// try preferred lang 
	console.log(langCntryCodes);
	let langBag = container[preferredLang];

	console.log(langBag);
	// first fallback any with lang code part matching preferred path
	if (langBag === undefined) {
		console.log("");
		const result = langCntryCodes.findIndex((lng) => { return lng.startsWith(preferredLang); }, preferredLang)
		if (result >= 0) {
			console.log("");
			const l = langCntryCodes[result];
			langBag = <ReadMoreReact key={keyPrefix + "-" + "_" + '-'} min={20} readMoreText=" ...(read more)" className="notPrefferedLang" max={140} text={container[l]} />
		}
	}
	// second fallback to all available languages
	if (langBag === undefined || langBag === "") {
		langBag = [];
		for (let i = 0; i < langCntryCodes.length; i++) {
			console.log("");
			if (container[langCntryCodes[i]] !== undefined) {
				if (typeof container[langCntryCodes[i]] === "object") {
					console.log("");
					container[langCntryCodes[i]].map((item, index) => {
						langBag.push(<ReadMoreReact title={"Preferred language " + preferredLang + " not available. Displaying " + langCntryCodes[i]} key={keyPrefix + "-" + i + '-' + langCntryCodes[i] + '-' + index} min={20} readMoreText=" ...(read more)" className="notPrefferedLang" max={140} text={container[langCntryCodes[i]][index]} />)
					});
				}
				else {
					langBag.push(<ReadMoreReact title={"Preferred language " + preferredLang + " not available. Displaying " + langCntryCodes[i]} key={keyPrefix + "-" + i + '-' + langCntryCodes[i]} min={20} readMoreText=" ...(read more)" className="notPrefferedLang" max={140} text={container[langCntryCodes[i]]} />);
				}
			}
		}
	}
	if (langBag === undefined) {
		langBag = ["n/a"];
	}
	return langBag;
}




