import React from 'react';
import { Switch } from 'react-router-dom';
import Loadable from 'react-loadable';

import Login from 'app/modules/login/login';
import Register from 'app/modules/account/register/register';
import Activate from 'app/modules/account/activate/activate';
import PasswordResetInit from 'app/modules/account/password-reset/init/password-reset-init';
import PasswordResetFinish from 'app/modules/account/password-reset/finish/password-reset-finish';
import Logout from 'app/modules/login/logout';
import Home from 'app/modules/home/home';
import About from 'app/modules/about/about';
import Apps from 'app/intro/apps/apps';
import Technical from 'app/intro/technical/technical';
import Manual from 'app/intro/manual/manual';
import Editor from 'app/intro/editor/editor';
import QSearch from 'app/intro/qsearch/qsearch';
import Converter from 'app/intro/converter/converter';
import Help from 'app/modules/help/help';
import Entities from 'app/entities';
import PrivateRoute from 'app/shared/auth/private-route';
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import PageNotFound from 'app/shared/error/page-not-found';
import { AUTHORITIES } from 'app/config/constants';
import Search from 'app/search/search-component';
import StudySearch from 'app/study-search/study-search-component';
import StudyDetail from 'app/study-search/study-search-detail';
import Study from 'app/search/study-component';
import StudyDocumentationDownload from 'app/search/studyDocumentationDownload-component';
import Question from 'app/search/question-component';

const Account = Loadable({
	loader: () => import(/* webpackChunkName: "account" */ 'app/modules/account'),
	loading: () => <div>loading ...</div>
});

const Admin = Loadable({
	loader: () => import(/* webpackChunkName: "administration" */ 'app/modules/administration'),
	loading: () => <div>loading ...</div>
});

const Routes = () => (
	<div className="view-routes">
		<Switch >
			<ErrorBoundaryRoute path="/login" component={Login} />
			<ErrorBoundaryRoute path="/logout" component={Logout} />
			<ErrorBoundaryRoute path="/account/register" component={Register} />
			<ErrorBoundaryRoute path="/account/activate/:key?" component={Activate} />
			<ErrorBoundaryRoute path="/account/reset/request" component={PasswordResetInit} />
			<ErrorBoundaryRoute path="/account/reset/finish/:key?" component={PasswordResetFinish} />
			<PrivateRoute path="/admin" component={Admin} hasAnyAuthorities={[AUTHORITIES.ADMIN]} />
			<PrivateRoute path="/account" component={Account} hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.USER]} />
			<ErrorBoundaryRoute path="/search" component={Search} />
			<ErrorBoundaryRoute path="/study/documentation/download" component={StudyDocumentationDownload} />
			<ErrorBoundaryRoute path="/study" component={Study}  />
			<ErrorBoundaryRoute path="/question/:id" component={Question} />
			<ErrorBoundaryRoute path={`/question/version/:id`} component={Question}  />
			<ErrorBoundaryRoute path={`/studysearch/detail/:id`} component={StudyDetail} />
			<ErrorBoundaryRoute path="/studysearch" component={StudySearch} />
			<ErrorBoundaryRoute path="/" exact component={Home} />
			<ErrorBoundaryRoute path="/about" exact component={About} />
			<ErrorBoundaryRoute path="/help" exact component={Help} />
			<ErrorBoundaryRoute path="/apps" exact component={Apps} />
			<ErrorBoundaryRoute path="/editor" exact component={Editor} />
			<ErrorBoundaryRoute path="/technical" exact component={Technical} />
			<ErrorBoundaryRoute path="/manual" exact component={Manual} />
			<ErrorBoundaryRoute path="/converter" exact component={Converter} />
			<ErrorBoundaryRoute path="/qsearch" exact component={QSearch} />
			<PrivateRoute path="/" component={Entities} hasAnyAuthorities={[AUTHORITIES.USER]} />
			<ErrorBoundaryRoute component={PageNotFound} />
		</Switch>
	</div>
);

export default Routes;
