import React from 'react';
import { Container, Row, Col } from 'reactstrap';
/* eslint no-console: 0  no-dupe-keys: 0 */
export const QSearch = () => {
	return (
		<Container>
			<Row>
				<Col md="12" >
					<h2>GESIS Question Search		</h2>
						Question Search helps researchers to find survey questions fielded anywhere in Europe. 
						Question adecuate for your own research can be <a href="/editor">compiled into new questionaires.</a>
				</Col>
			</Row>
		</Container>
	);
};
export default QSearch ;

