import './search.scss';
import './customStyle.scss';

import { Link } from 'react-router-dom';
import React, { useContext, useRef, useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { MatchQuery, SearchkitManager, SearchkitProvider, SearchBox, Hits, LayoutResults, Layout, LayoutBody } from "searchkit";

import BootstrapTable from 'react-bootstrap-table-next';
import * as componentUtility from "../search/componentUtility";
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { Button } from 'reactstrap';
import ReadMoreReact from "read-more-react";
import * as ReactDOM from "react-dom";
/* eslint no-console: 0 */
import appConfig from 'app/config/constants';

function StudyHitItem(props) {

	const langToggle = useRef();
	const [studyTitle, setStudyTitle] = useState([]);
	const [studyDescription, setStudyDescription] = useState([]);
	const [displayLang, setDisplayLang] = useState(props.preferredDisplayLanguage);
	const { result } = props;

	let effctvDsplyLng = props.preferredDisplayLanguage ? props.preferredDisplayLanguage : displayLang;
	function bestMatchingLang(data, la, dropdown, override) {
		if (override) {
			la = dropdown;
		}
		if (data === undefined) return;
		let preSelLang = override ? dropdown : effctvDsplyLng;
		if (dropdown.length > 2 && dropdown.startsWith(preSelLang) && dropdown.endsWith(preSelLang)) {
			return dropdown;
		}
		if (preSelLang === undefined) {
			preSelLang = data.filter((item, index) => item.startsWith(la) && item.endsWith(la));
		}
		if (preSelLang === undefined) {
			preSelLang = data.filter((item, index) => item.startsWith(la));
		}
		if (preSelLang === undefined) {
			preSelLang = la;
		}
		return preSelLang;
	}

	const setdisplayLang = (event) => {
		effctvDsplyLng = bestMatchingLang(result._source.studyTitles, effctvDsplyLng, event, true);
	}

	function getStudyDescriptions() {
		if (result._source.studyDescriptions !== undefined) {
			let studyDescription1 = undefined;
			console.info(effctvDsplyLng);
			console.info(result._source.studyDescriptions);
			studyDescription1 = componentUtility.langHandlerSD(result._source.studyDescriptions?.translations, effctvDsplyLng, "sd");

			console.info(studyDescription1);
			return studyDescription1 === undefined ? [] : studyDescription1;
		}
	}

	function getStudyTitle() {
		if (result._source.studyTitles !== undefined) {
			let studyTitle1 = undefined;
			studyTitle1 = componentUtility.langHandler(result._source.studyTitles.translations, effctvDsplyLng);
			return studyTitle1 === undefined ? [] : studyTitle1;
		}
	}

	function update() {
		console.info("");
		setStudyTitle(getStudyTitle())
		setStudyDescription(getStudyDescriptions())
	}

	const langSelector = (data) => {
		const ds = [];
		// flatten multiple
		data = data.filter((item, index) => data.indexOf(item) === index);
		data = data.sort();
		// find best match for preselection
		// exact? 
		if (data === undefined || data.length === 0) {
			return "";
		}
		if (data.length > 1) {
			ds.push(<option key={result._id + "-null"} value={effctvDsplyLng}>
				{componentUtility.langName(effctvDsplyLng)}</option>);
		}
		for (let i = 0; i < data.length; i++) {
			ds.push(<option key={result._id + "-" + data[i]} value={data[i]}>{componentUtility.langName(data[i])}</option>);
		}
		return <select
			className="btn btn-primary btn-sm langsel"
			ref={langToggle}
			selectedOptions={[effctvDsplyLng]}
			title={"Display fields in " + displayLang}
			onChange={e => { setdisplayLang(langToggle?.current.selectedOptions[0].value); update() }}  >
			{ds}
		</select>;

	}
	function getLanguages() {
		return langSelector(componentUtility.languagesOfStudyTitles(result));
	}

	const langSwitch = getLanguages();
	effctvDsplyLng = bestMatchingLang(result._source.languagesCountries, effctvDsplyLng,
		langToggle?.current?.selectedOptions !== undefined && langToggle?.current?.selectedOptions[0] !== undefined ? langToggle?.current?.selectedOptions[0].value : "en");

	useEffect(() => {

		setStudyTitle(getStudyTitle())
		setStudyDescription(getStudyDescriptions())

	}, [result, displayLang])

	if (studyTitle === undefined) { setStudyTitle(["no title"]) }

	return (<div data-qa="hit" className="list_hit" id={result._id}>
		<Row>
			<Col sm="12">
				{langSwitch}
			</Col>
		</Row>
		<Row >
			<Col sm="12">
				<strong>{studyTitle}</strong>
				
				<Button tag={Link} to={`/studysearch/detail/${result._id}`} color="link" size="sm">
									details
								</Button>
				<br />{studyDescription}
			</Col>
		</Row>
	</div >)
}
export default StudyHitItem;

